import { ethers } from 'ethers';
import * as React from 'react'

import addresses from '../resources/addresses.json';
import factory721ABI from '../resources/factory721ABI.json';
import factory1155ABI from '../resources/factory1155ABI.json';
import token721ABI from '../resources/token721ABI.json';
import token1155ABI from '../resources/token1155ABI.json';
import tokenWhitelistABI from '../resources/tokenWhitelistABI.json';
import marketplaceABI from '../resources/marketplaceABI.json';
import { 
  Box, 
  Button, 
  Center, 
  Container, 
  HStack, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  Spinner, 
  useToast,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
  Textarea
} from '@chakra-ui/react';
import { BsChevronBarDown, BsFiles } from 'react-icons/bs';
import { openInNewTab } from '../utils/externalLink';
import { Link } from 'react-router-dom';
import { csvToArray } from '../utils/csv';
import { Dropzone } from '../components/Dropzone';
import * as XLSX from 'xlsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InfoPopover } from '../components/InfoPopover';
import { isNonFungible } from '../utils/standards';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core'

function Manager() {
  const toast = useToast();
  const toastIdRef = React.useRef()
  const { library, active, chainId, account } = useWeb3React()

  // states
  const [isLoading, setIsLoading] = React.useState(true);
  const [contracts, setContracts] = React.useState([]);
  const [activeContract, setActiveContract] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isMarketplaceAdmin, setIsMarketplaceAdmin] = React.useState(false);
  const [reservationTokenAddress, setReservationTokenAddress] = React.useState("");
  const [marketplaceWhitelistTokenAddress, setMarketplaceWhitelistTokenAddress] = React.useState("");
  const [ownershipTargetAddress, setOwnershipTargetAddress] = React.useState("");

  const [itemsToReserve, setItemsToReserve] = React.useState(0);
  const [itemsToReserveValid, setItemsToReserveValid] = React.useState(true);
  const [itemsIdToReserve, setItemsIdToReserve] = React.useState(0);
  const [itemsIdToReserveValid, setItemsIdToReserveValid] = React.useState(true);
  const [addressesToWhitelist, setAddressesToWhitelist] = React.useState([]);
  const [addressesToWhitelistValid, setAddressesToWhitelistValid] = React.useState(true);
  const [addressesToRemoveFromWhitelist, setAddressesToRemoveFromWhitelist] = React.useState([]);
  const [addressesToRemoveFromWhitelistValid, setAddressesToRemoveFromWhitelistValid] = React.useState(true);
  const [tokenIds, setTokenIds] = React.useState([]);
  const [supplies, setSupplies] = React.useState([]);
  const [prices, setPrices] = React.useState([]);
  const [timestamps, setTimestamps] = React.useState([]);
  

  // effetc
  React.useEffect(() => {
      if (active) {
        handleUpdateCollections(false).then();
      }

  }, [active, account, chainId])

  // functions
  const handleCopyAddressToClipboard = async () => {
    toast({
      title: 'Token address copied to clipboard',
      status: 'info',
      duration: 9000,
      isClosable: true,
    });
  }

  const handleUpdateCollections = async (changeActiveContract) => {
    setIsLoading(true);

    if (changeActiveContract === undefined) {
      changeActiveContract = true
    }

    // get chain id
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16);

    const contractAddresses = addresses[chainId];

    // Check that the wallet is connected
    const signer = await library.getSigner();

    let walletAddress;
    
    try {
      walletAddress = await signer.getAddress();
    } catch (e) {
      setIsLoading(false);

      toast.closeAll();

      toast({
        title: 'Connect your wallet',
        description: "In order to manage collections you must connect your wallet",
        status: 'error',
        duration: null,
        isClosable: true,
      });

      return;
    }

    const factory721 = new ethers.Contract(contractAddresses.factory721, factory721ABI, signer);
    const factory1155 = new ethers.Contract(contractAddresses.factory1155, factory1155ABI, signer);

    const response = await axios.get(process.env.REACT_APP_API_URL + "/collection?creator=" + walletAddress + "&chainId=" + chainId)
    if (response.status !== 200) {
      setActiveContract(null);
      toast.closeAll();
      toast({
        title: "You don't own any NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    const newContracts = response.data.collections

    setIsLoading(false);
    setContracts(newContracts);

    if (newContracts.length > 0) {
      if (changeActiveContract) {
        setActiveContract(newContracts[0]);
      }
    } else {
      setActiveContract(null);
      toast.closeAll();
      toast({
        title: "You don't own any NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    // // Check if it's admin
    const admin721 = await factory721.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);
    const admin1155 = await factory1155.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);
    setIsAdmin(admin721 || admin1155);

    // Check if it's marketplace admin
    const marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer);
    const marketplaceAdmin = await marketplace.hasRole("0x0000000000000000000000000000000000000000000000000000000000000000", walletAddress);

    setIsMarketplaceAdmin(marketplaceAdmin);
  }

  const handleSetItemsToReserve = (value) => {
    const isNumber = /^\d+/.test(value);

    // Check that it's a number
    if (! isNumber) {
      setItemsToReserve(value);
      setItemsToReserveValid(false);
      return
    }

    const number = parseInt(value)

    // Check ranges
    if (number <= 0.0) {
      setItemsToReserveValid(false);
    } else {
      setItemsToReserveValid(true);
    }
    
    setItemsToReserve(value);
  }

  const handleSetItemsIdToReserve = (value) => {
    const isNumber = /^\d+/.test(value);

    // Check that it's a number
    if (! isNumber) {
      setItemsIdToReserve(value);
      setItemsIdToReserveValid(false);
      return
    }

    const number = parseInt(value)

    // Check ranges
    if (number < 0.0) {
      setItemsIdToReserveValid(false);
    } else {
      setItemsIdToReserveValid(true);
    }
    
    setItemsIdToReserve(value);
  }

  const handleAddItemsToWhitelist = (addresses) => {
    // Remove spaces
    setAddressesToWhitelist(addresses);

    addresses = addresses.replace(/\s+/g, '');
    addresses = addresses.split(',');

    if (addresses.length > 100) {
      toast({
        title: "Whitelist error",
        description: 'Cannot whitelist more than 100 addresses at a time',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setAddressesToWhitelistValid(false);

      return;
    } else {
      setAddressesToWhitelistValid(true);
    }
  }

  const handleAddItemsToRemoveFromWhitelist = (addresses) => {
    // Remove spaces
    setAddressesToRemoveFromWhitelist(addresses);
    
    addresses = addresses.replace(/\s+/g, '');  
    addresses = addresses.split(',');

    if (addresses.length > 100) {
      toast({
        title: "Whitelist error",
        description: 'Cannot remove from whitelist more than 100 addresses at a time',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setAddressesToRemoveFromWhitelistValid(false);

      return;
    } else {
      setAddressesToRemoveFromWhitelistValid(true);
    }
  }

  const handleWhitelistDrop = async (e, shouldAdd) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // process file
        const reader = new FileReader();
        if (shouldAdd) {
            reader.onloadend = handleAddFromCsv;
        } else {
            reader.onloadend = handleRemoveFromCsv;
        }
        
        reader.readAsText(e.dataTransfer.files[0]);

        e.dataTransfer.clearData();
    }
  }

  const handleAddFromCsv = async (event) => {
    const data = event.target.result;

    const rawAddresses = csvToArray(data);
    let addresses = "";
    for (let i = 0; i < rawAddresses.length; i++) {
        if (rawAddresses[i].address !== "") {
            addresses += rawAddresses[i].address + ",";
        }
    }

    if (addresses[addresses.length - 1] === ",") {
        addresses = addresses.slice(0, -1); 
    }

    setAddressesToWhitelist(addresses);
  }

  const handleRemoveFromCsv = async (event) => {
    const data = event.target.result;

    const rawAddresses = csvToArray(data);
    let addresses = "";
    for (let i = 0; i < rawAddresses.length; i++) {
        if (rawAddresses[i].address !== "") {
            addresses += rawAddresses[i].address + ",";
        }
    }

    if (addresses[addresses.length - 1] === ",") {
        addresses = addresses.slice(0, -1); 
    }

    setAddressesToRemoveFromWhitelist(addresses);
  }

  const processInitializationdata = (setup) => {
    let ids = [];
    let basePrices = [];
    //let tokenSupplies = [];
    let startTimestamps = [];

    for(let i = 1; i < setup.length; i++) {
      if (setup[i].length === 0) {
        continue;
      }

      ids.push(setup[i][0]);
      //tokenSupplies.push(setup[i][1]);
      basePrices.push(ethers.utils.parseEther(setup[i][1].toString()));

      // Process mint start
      const startDate = setup[i][2];
      const timestamp = Date.parse(startDate) / 1000;

      startTimestamps.push(timestamp);
    }

    console.log(ids, basePrices, /* tokenSupplies, */ startTimestamps);

    setTokenIds(ids);
    setPrices(basePrices);
    // setSupplies(tokenSupplies);
    setTimestamps(startTimestamps);

    toast({
      title: "Configuration file uploaded",
      duration: 9000,
      status: 'info',
      isClosable: true,
    });
    return;
  }

  const handleProcessNewInitializationFile = (event) => {
    const content = event.target.result;
    const wb = XLSX.read(content, { type: 'binary' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const json = XLSX.utils.sheet_to_json(ws, { header: 1});

    processInitializationdata(json);
  }

  const handleNewInitializationFileUpload = async (files) => {
    if(files.length === 0) {
      toast({
        title: "Upload a configuration file",
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = handleProcessNewInitializationFile;

    reader.readAsBinaryString(files[0]);
  }
  
  // ERC1155 SETUP
  const handleSetup = async () => {
    const signer = await library.getSigner();
    const contract = new ethers.Contract(activeContract.contractAddress, token1155ABI, signer);

    if (tokenIds.length === 0 || prices.length === 0 || timestamps.length === 0 /* || supplies.length === 0 */) {
      toast({
        title: "Invalid configuration file",
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    // info toast
    toast.closeAll();
    toastIdRef.current = toast({
      title: "Token setup",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    // Create contract
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];
    
    // Call the enable ids method
    try {
      let tx = await contract.enableIds(tokenIds, /* supplies, */ prices, timestamps);
      
      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )
      toast.update(toastIdRef.current, { title: "Token setup", description: description })

      await tx.wait();
      
      toast.update(toastIdRef.current, { title: "Token setup completed", description: description, status: 'success', duration: 9000 });
    } catch(error) {
      // setInitializerStatus(error.message);
      //setInitializerStatus("You cannot initialize the same token ID multiple times!");
      toast.closeAll();
      toast({
        title: "Unable to setup your token",
        description: 'Your configuration file is not valid or you are trying to initialize the same token id multiple times',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });

      console.log(error);
    }
  }

  

  // RESERVE METHOD
  const handleReserve = async () => {
    // sanity checks
    if (! itemsIdToReserveValid) {
      toast({
        title: "Incorrect number of items to reserve",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (! isNonFungible(activeContract) && ! itemsIdToReserveValid) {
      toast({
        title: "Incorrect NFTs to reserve id",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const amount = parseInt(itemsToReserve);

    let tokenId = 0;
    if (! isNonFungible(activeContract)) {
      tokenId = parseInt(itemsIdToReserve);
    }

    // info toast
    toast.closeAll();
    toastIdRef.current = toast({
      title: "Reserving NFTs",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    // get connected wallet
    const signer = await library.getSigner();

    // Create contract
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    // reserve
    let tx = null;
    let contract = null;
    try {
      if (isNonFungible(activeContract)) {
        contract = new ethers.Contract(activeContract.contractAddress, token721ABI, signer);
        tx = await contract.reserve(amount);
      } else {
        contract = new ethers.Contract(activeContract.contractAddress, token1155ABI, signer);
        tx = await contract.reserve(tokenId, amount, "0x00");
      } 

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )
      toast.update(toastIdRef.current, { title: "Reserving NFTs", description: description })

      // transaction is completed after this
      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "NFTs reserved successfully", description: description , duration: 9000, status: 'success'});
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to reserve your NFTs",
        description: 'Cannot reserve more NFTs tran the supply or after mint started',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
    }
  }

  // Whitelist methods
  const addToWhitelist = async () => {
    const signer = await library.getSigner();
    const contract = new ethers.Contract(activeContract.whitelistAddress, tokenWhitelistABI, signer);

    // Remove spaces
    let wlAddresses = addressesToWhitelist;
    wlAddresses = wlAddresses.replace(/\s+/g, '');
    wlAddresses = wlAddresses.split(',');

    if (wlAddresses.length > 100) {
      toast({
        title: "Unable to whitelist",
        description: 'Cannot whitelist more than 100 addresses at a time',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (wlAddresses.length === 0) {
      toast({
        title: "Unable to whitelist",
        description: 'Please insert at leat one address',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    for (let i = 0; i < wlAddresses.length; i++) {
        if (! ethers.utils.isAddress(wlAddresses[i])) {
          toast({
            title: "Unable to whitelist",
            description: wlAddresses[i] + ' is not a valid address',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
            return;
        }
    }

    // info toast
    toast.closeAll();
    toastIdRef.current = toast({
      title: "Whitelisting",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    try {
      const tx = await contract.addToWhitelistMassive(wlAddresses);

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )
      toast.update(toastIdRef.current, { title: "Whitelisting", description: description })

      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "Addresses whitelisted successfully", description: description , duration: 9000, status: 'success'});
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to whitelist",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
    }
  }

  const removeFromWhitelist = async () => {
    const signer = await library.getSigner();
    const contract = new ethers.Contract(activeContract.whitelistAddress, tokenWhitelistABI, signer);

    // Remove spaces
    let wlAddresses = addressesToRemoveFromWhitelist;
    wlAddresses = wlAddresses.replace(/\s+/g, '');
    wlAddresses = wlAddresses.split(',');

    if (wlAddresses.length > 100) {
      toast({
        title: "Unable to remove from whitelist",
        description: 'Cannot whitelist more than 100 addresses at a time',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (wlAddresses.length === 0) {
      toast({
        title: "Unable to remove from whitelist",
        description: 'Please insert at leat one address',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    for (let i = 0; i < wlAddresses.length; i++) {
        if (! ethers.utils.isAddress(wlAddresses[i])) {
          toast({
            title: "Unable to remove from whitelist",
            description: wlAddresses[i] + ' is not a valid address',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
            return;
        }
    }

    // info toast
    toast.closeAll();
    toastIdRef.current = toast({
      title: "Removing from whitelist",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    try {
      const tx = await contract.removeFromWhitelistMassive(wlAddresses);

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )
      toast.update(toastIdRef.current, { title: "Removing from whitelist", description: description })

      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "Addresses successfully removed from whitelist", description: description , duration: 9000});
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to remove from whitelist",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
    }
  }

  const setReservationFunction = async (isActive) => {
    if (! ethers.utils.isAddress(reservationTokenAddress)) {
      toast.closeAll();
        toast({
          title: "Invalid reservation token address",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
    }

    const signer = await library.getSigner();

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    const factory721 = new ethers.Contract(contractAddresses.factory721, factory721ABI, signer);
    const factory1155 = new ethers.Contract(contractAddresses.factory1155, factory1155ABI, signer);

    // check if the collection is 721 or 1155
    let is721 = await factory721.tokenDeployed(reservationTokenAddress);
    if (! is721) {
      let is1155 = await factory1155.tokenDeployed(reservationTokenAddress);
      if (!is1155) {
        toast.closeAll();
        toast({
          title: "Reservation token is not a BlockInvest collection",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      else {
        is721 = false;
      }
    }

    console.log(reservationTokenAddress, isActive, is721);

    toast.closeAll();
    toastIdRef.current = toast({
      title: "Reservation",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })


    try {
      let tx;
      if (is721) {
        tx = await factory721.enableReserveFunction(reservationTokenAddress, isActive);
      } else {
        tx = await factory1155.enableReserveFunction(reservationTokenAddress, isActive);
      }

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )
      let title = (isActive ? "Enabling" : "Disabling") + " reserve function";
      toast.update(toastIdRef.current, { title: title, description: description })

      await tx.wait();

      // update for the transaction to be completed
      title = "Reserve function " + (isActive ? "enabled" : "disabled") ;
      toast.update(toastIdRef.current, { title: title, description: description, status: 'success', duration: 9000});

      handleUpdateCollections(false).then();
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to change reservation function state",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
    }
  }

  const handleAddTokenToMarketplace = async () => {
    if (! ethers.utils.isAddress(marketplaceWhitelistTokenAddress)) {
      toast.closeAll();
        toast({
          title: "Invalid token address",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
    }

    const signer = await library.getSigner();

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)

    const owner = await signer.getAddress()

    // construct message and sign it
    const message = marketplaceWhitelistTokenAddress + owner + chainId + "1" // whitelist by default

    let signature = ""
    try {
      signature = await signer.signMessage(message)
    } catch (error) {
      console.log("Unable to sign message:", error.message);

      toast({
        title: "Signature rejected",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return
    }

    // Update backend
    axios.post(process.env.REACT_APP_API_URL + '/marketplace/whitelist', {
      collection: marketplaceWhitelistTokenAddress,
      owner: owner,
      chainId: chainId,
      whitelisted: true,
      signature: signature
    })
    .then(response => {
      toast({
        title: "Marketplace whitelisted",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    })
    .catch(error => {
      toast({
        title: "Unable to whitelist collection",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    })


    // const contractAddresses = addresses[chainId];

    // toast.closeAll();
    // toastIdRef.current = toast({
    //   title: "Add to marketplace",
    //   description: "Creating transaction",
    //   status: 'info',
    //   duration: null,
    //   isClosable: false,
    // })

    // const marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer)

    // try {
    //   let tx = await marketplace.addCollectionToWhitelist(marketplaceWhitelistTokenAddress, true);

    //   // update for the transaction to be completed
    //   const explorer = contractAddresses.explorer + tx.hash;
    //   let description = (
    //     <span>
    //       <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
    //     </span>
    //   )
    //   let title = "Adding collection to marketplace";
    //   toast.update(toastIdRef.current, { title: title, description: description })

    //   await tx.wait();

    //   // update for the transaction to be completed
    //   title = "Collection successfully added to marketplace";
    //   toast.update(toastIdRef.current, { title: title, description: description, status: 'success', duration: 9000});
    // } catch (error) {
    //   toast.closeAll();
    //   toast({
    //     title: "Unable to add collection to Marketplace",
    //     status: 'error',
    //     duration: 9000,
    //     isClosable: true,
    //   });

    //   console.log(error.message);
    // }
  }

  const handleTransferOwnership = async () => {
    if (! ethers.utils.isAddress(ownershipTargetAddress)) {
      toast.closeAll();
        toast({
          title: "Invalid owner address",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
    }

    const signer = await library.getSigner();

    toast.closeAll();
    toastIdRef.current = toast({
      title: "Transfer ownership",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    try {
      const contract = new ethers.Contract(activeContract.contractAddress, isNonFungible(activeContract) ? token721ABI : token1155ABI, signer);
      let tx = await contract.setCreatorAddress(ownershipTargetAddress);

      // update for the transaction to be completed
      // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
      // chainId = parseInt(chainId, 16)
      const contractAddresses = addresses[chainId];
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )

      toast.update(toastIdRef.current, { title: "Transferring ownership", description: description })

      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "Ownership transferred", description: description, status: 'success', duration: 9000});
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to change ownership of this collection",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
      return;
    }

    // Remove the contracts
    const newContracts = [...contracts];
    for (let i = 0; i < newContracts.length; i++) {
      if (newContracts[i].address === activeContract.contractAddress) {
        newContracts.splice(i, 1);
        break;
      }
    }

    setContracts(newContracts)
    
    if (newContracts.length > 0) {
      setActiveContract(newContracts[0]);
      toast({
        title: "You are now managing another collection.",
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    } else {
      setActiveContract(null);
    }
  }

  return (
    <>
    <Container py={{ base: '4', md: '8' }}>
    {
      // On load
      isLoading && (
        <Box w="100%" h="55vh">
          <Center h="100%">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        </Box>
      )
    }

    {
      ! isLoading && (
        <>
        <HStack>
          <Menu colorScheme='blue'>
            <MenuButton as={Button} rightIcon={<BsChevronBarDown />}>
              { 
                activeContract ? activeContract.contractAddress + " - " + activeContract.tokenName  : "Choose the collection to manage"
              }
            </MenuButton>
            <MenuList maxHeight="300px" overflowY="scroll">
              {
                contracts.map((contract) => (
                  <MenuItem onClick={() => {setActiveContract(contract)}} >{contract.contractAddress} {contract.tokenName !== "" ? " - " + contract.tokenName : ""}</MenuItem>
                ))
              }
            </MenuList>
          </Menu>

          
          { 
            activeContract && (
              <CopyToClipboard text={activeContract.contractAddress}
                onCopy={handleCopyAddressToClipboard}
                style={{cursor: "pointer"}}>
                <BsFiles height={35} size={32} />
              </CopyToClipboard>
            )
          }
        </HStack>


        {
          ! activeContract && (
            <Box w="100%" h="60vh" />
          )
        }
        </>
      )
    }

    {
      // Connected and had deployed at least one collection
      ! isLoading && activeContract !== null && (
        <>
        {
          ! isNonFungible(activeContract) && (
        
          <Stack marginTop="5" spacing="5">
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  Token setup
                  {' '} <InfoPopover header="Token setup" body="Initialize your token by selecting mint price, mint date and total supply for each item."/>
                </Text>
                <Text color="muted" fontSize="sm">
                  This is the first step you must do before anything else. Click 
                  <Link to="/resources/setup_template.xlsx" style={{ color: '#0000EE'}} target="_blank" download> here </Link>
                  to download a sample configuration file. 
                </Text>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="setup">
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Configuration file
                    {' '} <InfoPopover header="Configuration file" body="File that contains all informations needed to setup your token."/>
                  </FormLabel>

                  <Stack
                    spacing={{ base: '3', md: '5' }}
                    direction={{ base: 'column', sm: 'row' }}
                    width="full"
                    maxW={{ md: '3xl' }}
                  >
                    <Dropzone width="full" updateFiles={handleNewInitializationFileUpload} initialText="XLSX file" accepted=".xlsx"/>
                  </Stack>
                </Stack>

                <Flex direction="row-reverse" marginTop="5">
                  <Button variant="primary" onClick={handleSetup}>Setup</Button>
                </Flex>
              </FormControl>
            </Stack>
          </Stack>
        )}

        {
          isAdmin && (
        
          <Stack marginTop="5" spacing="5">
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  Token reservation
                  {' '} <InfoPopover header="Token reservation" body="Reserving means minting without paying. It's a procedure commonly used for airdrops."/>
                </Text>
                <Text color="muted" fontSize="sm">
                  Here you can enable or disable the reserve function of a collection.
                </Text>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="tokenAddress">
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Token address
                    {' '} <InfoPopover header="Token address" body="Address of the collection where you want to enable/disable reservation"/>
                  </FormLabel>

                  <Input maxW={{ md: '3xl' }} placeholder="0x756bFB8A31b2a21823152D96c88C6B8ae4aB1E99" value={reservationTokenAddress} 
                    onChange={(e) => {setReservationTokenAddress(e.target.value)}}/>
                </Stack>

                
                  <Flex direction="row-reverse" marginTop="5">
                  <HStack spacing="5">
                    <Button variant="primary" onClick={() => {setReservationFunction(true)}}>Enable</Button>
                    <Button variant="primary" onClick={() => {setReservationFunction(false)}}>Disable</Button>
                  </HStack>
                  </Flex>
              </FormControl>
            </Stack>
          </Stack>
          )
        }

        {
          isMarketplaceAdmin && (
        
          <Stack marginTop="5" spacing="5">
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  Marketplace whitelist
                  {' '} <InfoPopover header="Marketplace whitelist" body="Whitelisting a collection means that token holders will be able to sell their NFTs."/>
                </Text>
                <Text color="muted" fontSize="sm">
                  Here you can add or remove a collection from the marketplace whitelist
                </Text>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="tokenAddress">
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Token address
                    {' '} <InfoPopover header="Token address" body="Address of the collection that you want to add / remove from the marketplace"/>
                  </FormLabel>

                  <Input maxW={{ md: '3xl' }} placeholder="0x756bFB8A31b2a21823152D96c88C6B8ae4aB1E99" value={marketplaceWhitelistTokenAddress} 
                    onChange={(e) => {setMarketplaceWhitelistTokenAddress(e.target.value)}}/>
                </Stack>

                
                  <Flex direction="row-reverse" marginTop="5">
                  <HStack spacing="5">
                    <Button variant="primary" onClick={() => {handleAddTokenToMarketplace(true)}}>Add to marketplace</Button>
                  </HStack>
                  </Flex>
              </FormControl>
            </Stack>
          </Stack>
          )
        }

{
          activeContract.whitelistAddress !== ethers.constants.AddressZero && (
        
          <Stack marginTop="5" spacing="5">
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  Whitelist addresses
                  {' '} <InfoPopover header="Whitelist addresses" body="Whitelisted addresses are the only addresses that are allowed to receive NFTs from your collection."/>
                </Text>
                <Text color="muted" fontSize="sm">
                  Here you can add or remove addresses to your whitelist. Click 
                  <Link to="/resources/whitelist_template.csv" style={{ color: '#0000EE'}} target="_blank" download> here </Link>
                  to download a sample whitelisting file. 
                </Text>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="reserveAmount">
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Addresses to whitelist
                    {' '} <InfoPopover header="Addresses to whitelist" body="List of addresses that you want to whitelist separated by a comma"/>
                  </FormLabel>

                  <Textarea maxW={{ md: '3xl' }} rows={5} placeholder="Drop a .csv file or write the addresses to whitelist separated by a comma." 
                      minHeight="40px" value={addressesToWhitelist} onChange={(e) => {handleAddItemsToWhitelist(e.target.value)}} 
                      isInvalid={! addressesToWhitelistValid} onDrop={(e) => { handleWhitelistDrop(e, true); }}  />
                </Stack>

                <Flex direction="row-reverse" marginTop="5">
                  <Button variant="primary" onClick={addToWhitelist}>Add to whitelist</Button>
                </Flex>

                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                  marginTop="5"
                >
                  <FormLabel variant="inline">
                    Remove addresses from whitelist
                    {' '} <InfoPopover header="Remove addresses" body="List of addresses that you want to remove from the whitelist separated by a comma."/>
                  </FormLabel>

                  <Textarea maxW={{ md: '3xl' }} rows={5} placeholder="Drop a .csv file or write the addresses to remove from whitelist separated by a comma." 
                      minHeight="40px" value={addressesToRemoveFromWhitelist} onChange={(e) => {handleAddItemsToRemoveFromWhitelist(e.target.value)}} 
                      isInvalid={! addressesToRemoveFromWhitelistValid} onDrop={(e) => { handleWhitelistDrop(e, false); }}  />
                </Stack>

                <Flex direction="row-reverse" marginTop="5">
                  <Button variant="primary"  onClick={removeFromWhitelist}>Remove from whitelist</Button>
                </Flex>
              </FormControl>
            </Stack>
          </Stack>
          )}

        {
          activeContract.reserveEnabled && (
            <Stack marginTop="5" spacing="5">
              <Stack
                spacing="4"
                direction={{
                  base: 'column',
                  sm: 'row',
                }}
                justify="space-between"
              >
                <Box>
                  <Text fontSize="lg" fontWeight="medium">
                    Reserve items
                    {' '} <InfoPopover header="Reserve items" body="Mint items without paying the mint price. It's commonly used for airdrops."/>
                  </Text>
                  <Text color="muted" fontSize="sm">
                    Here you can pre-mint some of your assets. This procedure is usually used for airdrops.
                  </Text>
                </Box>
              </Stack>
              <Divider />
              <Stack spacing="5" divider={<StackDivider />}>
                <FormControl id="reserveAmount">
                  <Stack
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}
                    spacing={{
                      base: '1.5',
                      md: '8',
                    }}
                    justify="space-between"
                  >
                    <FormLabel variant="inline">
                      NFTs to reserve
                      {' '} <InfoPopover header="NFTs to reserve" body="Number of NFTs you want to reserve."/>
                    </FormLabel>

                    <Input maxW={{ md: '3xl' }} placeholder="0" value={itemsToReserve} 
                        onChange={(e) => {handleSetItemsToReserve(e.target.value)}} isInvalid={! itemsToReserveValid}/>
                  </Stack>

                  {
                    ! isNonFungible(activeContract) && (
                      <Stack
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                        spacing={{
                          base: '1.5',
                          md: '8',
                        }}
                        justify="space-between"
                        marginTop="5"
                      >
                        <FormLabel variant="inline">
                          NFT id
                          {' '} <InfoPopover header="NFT id" body="ID of the NFT you want to reserve"/>
                        </FormLabel>

                        <Input maxW={{ md: '3xl' }} placeholder="0" value={itemsIdToReserve} 
                            onChange={(e) => {handleSetItemsIdToReserve(e.target.value)}} isInvalid={! itemsIdToReserveValid}/>
                      </Stack>
                    )
                  }

                  <Flex direction="row-reverse" marginTop="5">
                    <Button variant="primary" onClick={handleReserve}>Reserve</Button>
                  </Flex>
                </FormControl>
              </Stack>
            </Stack>
          )
        }

        {/* Ownership management */}
          <Stack marginTop="5" spacing="5">
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Text fontSize="lg" fontWeight="medium">
                  Transfer ownership
                  {' '} <InfoPopover header="Transfer ownership" body="Changes the owner of a collection."/>
                </Text>
                <Text color="muted" fontSize="sm">
                  Here you can transfer the ownership of this collection.
                </Text>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="tokenAddress">
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                  spacing={{
                    base: '1.5',
                    md: '8',
                  }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">
                    Owner address
                    {' '} <InfoPopover header="Owner address" body="Address of the new owner of the collection"/>
                  </FormLabel>

                  <Input maxW={{ md: '3xl' }} placeholder="0x756bFB8A31b2a21823152D96c88C6B8ae4aB1E99" value={ownershipTargetAddress} 
                    onChange={(e) => {setOwnershipTargetAddress(e.target.value)}}/>
                </Stack>

                
                  <Flex direction="row-reverse" marginTop="5">
                  <HStack spacing="5">
                    <Button variant="primary" onClick={handleTransferOwnership}>Transfer ownership</Button>
                  </HStack>
                  </Flex>
              </FormControl>
            </Stack>
          </Stack>
        </> 
      )
    }

    </Container>
    </>
  )
}

export default Manager;