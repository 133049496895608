export const getNameFromDocumentUrl = (url) => {
  const parts = url.split("/")

  return parts.at(-1)
}

export const getIconPathFromDocumentUrl = (url) => {
  const parts = url.split(".")
  let extension = parts.at(-1)

  const allowedExtensions = ["pdf", "xlsx", "xls", "docx"]

  if (! allowedExtensions.includes(extension)) {
    extension = "docx"
  }

  if (extension === "xlsx") {
    extension = "xls"
  }

  return "/" + extension + "_icon.png"
}