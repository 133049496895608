import * as React from 'react'

import addresses from '../resources/addresses.json';
import axios from 'axios';
import { 
  Box, 
  Center, 
  Container, 
  Spinner, 
  useToast,
} from '@chakra-ui/react';
import { CollectionGrid } from '../components/collection/CollectionGrid';
import { CollectionCard } from '../components/collection/collectionCard';
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { useWeb3React } from '@web3-react/core'

function Collection() {
  const toast = useToast();
  const { active, account, chainId } = useWeb3React()

  // states
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [itemsToShow, setItemsToShow] = React.useState([]);
  const [collectionNames, setCollectionNames] = React.useState([]);
  const [pickerItems, setPickerItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [sellTokens, setSellTokens] = React.useState([])

  // effetc
  React.useEffect(() => {
    if (active) {
        updateItems().then();
    }
  }, [active, account, chainId])

  // functions
  const updateItems = async () => {
    console.log("Loading assets:", isLoading);
    if (isLoading) {
      return;
    }

    if (! active) {
      console.log("Connect your wallet");
      setIsLoading(false);

      return
    }
  
    setIsLoading(true);


    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    setSellTokens(contractAddresses.tokens)

    // // Process ERC721 tokens
    const walletAddress = account

    const response = await axios.get(process.env.REACT_APP_API_URL + "/token?owner=" + walletAddress + "&chainId=" + chainId)
    if (response.status !== 200) {
      setItems([]);
      setItemsToShow([]);
      setCollectionNames([]);
      setIsLoading(false);
      
      toast.closeAll();
      toast({
        title: "You don't own any NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    const nfts = response.data.tokens    
    console.log("NFTS", nfts)

    // Process collection name
    const individualNames = []
    nfts.forEach(nft => {
      let collectionName = ""
      if (nft.metadataName.includes("#")) {
        let splittedName = nft.metadataName.split("#");

        console.log("splitted name", splittedName)

        const removed = splittedName.pop();
        const toRemoveLength = removed.length + 1; // length + 1 (#)
        const end = nft.metadataName.length - toRemoveLength
        collectionName = nft.metadataName.substring(0, end);
      } else {
        collectionName = nft.metadataName
      }

      // check if name isn't already inserted
      if (! individualNames.find(item => item.label === collectionName)) {
        individualNames.push({
            value: collectionName,
            label: collectionName,
        });
      }
    })

    if (nfts.length === 0) {
      toast({
        title: "You don't have any NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    setItems(nfts);
    setItemsToShow(nfts);
    setCollectionNames(individualNames);

    console.log(individualNames)
    setIsLoading(false);
  }

  
  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      // the item selected
      let toShow = [];

      for (let i = 0; i < selectedItems.length; i++) {
        for (let j = 0; j < items.length; j++) {
          if (items[j].metadataName.includes(selectedItems[i].label)) {
            toShow.push(items[j]);
          }
        }
      }

      if (toShow.length === 0) {
        setItemsToShow(items);
      } else {
        setItemsToShow(toShow);
      }
      
      setSelectedItems(selectedItems)
    } else {
      setItemsToShow(items);
    }
  };
  
  return (
    <>
    <Container py={{ base: '4', md: '8' }}>
    {
      // On load
      isLoading && (
        <Box w="100%" h="55vh">
          <Center h="100%">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        </Box>
      )
    }

    {
      // wallet not connected
      ! isLoading && itemsToShow.length === 0 && (
        <Box w="100%" h="60vh" />
      )
    } 

    {
      ! isLoading && items.length > 0 && (
        <>
        <CUIAutoComplete
          label="Looking for a specific NFT?"
          placeholder={collectionNames[0].label}
          disableCreateItem={true}
          items={collectionNames}
          tagStyleProps={{
            rounded: "full",
            pt: 1,
            pb: 2,
            px: 2,
            fontSize: "1rem"
          }}
          selectedItems={selectedItems}
          onSelectedItemsChange={(changes) =>
            handleSelectedItemsChange(changes.selectedItems)
          }
        />
      <CollectionGrid>
        {
          itemsToShow.map((item) => (
            <CollectionCard key={item.key} item={item} sellTokens={sellTokens}/>
          ))
        }
        </CollectionGrid>
        </>
      )
    }

    </Container>
    </>
  )
}

export default Collection;