import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { isVideo } from '../../utils/file'

export const MarketplaceCard = (props) => {

const { name, image, address, showCollection, rootProps } = props


return (
  <>
  <Stack
    spacing={useBreakpointValue({
      base: '4',
      md: '5',
    })}
    {...rootProps}
  >
    <Box position="relative" maxW={"xs"}>
      <AspectRatio ratio="1">
        {
          isVideo(image) ? (
            <Box 
              as='video'
              src={image}
              alt={name}
              objectFit='contain'
              sx={{
                aspectRatio: '1'
              }}
            />
          ) : (
            <Image
              src={image}
              alt={name}
              draggable="false"
              fallback={<Skeleton />}
              height="100%"
              borderRadius={['xl','md']}
            />
          )
        }
      </AspectRatio>
    </Box>
    <Stack>
      <Stack spacing="1">
        <Text fontWeight="medium" color='gray.700'>
          {name.length > 20 ? name.substring(0, 18).trim() + "..." : name}
        </Text>
      </Stack>
    </Stack>
    <Stack maxW={"xs"}>
      <Button onClick={() => {showCollection(address)}}>Show collection</Button>
    </Stack>
  </Stack>
  </>
  )
}