import { pinImageToIPFS, pinMetadataToIPFS } from '../utils/pinata';
import axios from 'axios';

export const uploadCollectionMetadata = async (files, collectionName, collectionDescription, attributes, updateInfoToast) => {

    updateInfoToast("Uploading media", "Veryfing files...")

    // File size should less than 100MB
    let size = 0;
    // 1GB
    if (size > 1000000000) {
        return {
            error: "Images are too big",
            success: false,
            ipfsHash: null
        }
    }

    // Check file names
    let names = [];
    files.forEach(file => {
        size += file.size;
        
        // Split file name by .
        const parts = file.name.split('.');
        if (parts.length !== 2) {
            return {
                error: "Incorrect file naming:" + file.name,
                success: false,
                ipfsHash: null
            }
        }
        
        const id = parseInt(parts[0]);
        if (isNaN(id)) {
            return {
                error: "Incorrect file naming:" + file.name,
                success: false,
                ipfsHash: null
            }
        }

        // Check that id is unique
        if (names.find(elem => elem === id)) {
            return {
                error: "Duplicate name: " + file.name,
                success: false,
                ipfsHash: null
            }
        }

        names.push(id);
    })

    // Check there are no gaps in ids
    for (let i = 0; i < names.length; i++) {
        if (names[i] !== i) {
            return {
                error: "Missing image with id" + i.toString(),
                success: false,
                ipfsHash: null
            }
        }
    }

    // Generate metadata and upload files

    let metadataList = [];
    for (let i = 0; i < names.length; i++) {
        updateInfoToast("Uploading media", "Uploading file " + i + " / " + names.length);

        // Upload image to Pinata
        const ipfsHash = await pinImageToIPFS(files[i]);
        if (ipfsHash === null) {
            return {
                error: "Unable to upload your files to IPFS",
                success: false,
                ipfsHash: null
            }
        }

        // Create Metadata
        let metadata = {
            name: collectionName + " #" + i.toString(),
            description: collectionDescription,
            image: "https://blockinvest.mypinata.cloud/ipfs/" + ipfsHash,
            attributes: []
        }

        if (attributes.length > 0) {
            for (let i = 0; i < attributes.length; i++) {
                metadata[attributes[i].field] = attributes[i].value;
            }
        }

        metadataList.push(metadata);
    }

    // Upload metadata as a directory
    let metadataFiles = [];
    for (let i = 0; i < metadataList.length; i++) {
        const blob = new Blob([JSON.stringify(metadataList[i], null, 2)], { type: 'text/json' });
        metadataFiles.push(blob);
    }

    updateInfoToast("Uploading media", "Generating metadata");
    const metadataIpfsHash = await pinMetadataToIPFS(metadataFiles, collectionName);
    if (metadataIpfsHash === null) {
        return {
            error: "Unable to upload metadata to IPFS",
            success: false,
            ipfsHash: null
        }
    }

    console.log("Collection [", collectionName, "] CID:", metadataIpfsHash);

    return {
        error: null,
        success: true,
        ipfsHash: metadataIpfsHash
    }
}

export const uploadMediaToS3 = async (mediaFile, metadataId, owner, signature) => {
  const requestData = new FormData()
  requestData.append("media", mediaFile)
  requestData.append("metadataId", metadataId)
  requestData.append("signature", signature)
  requestData.append("owner", owner)

  const response = await axios.post(process.env.REACT_APP_API_URL + '/metadata/media', requestData)

  if (response.status === 200) {
    return true
  }

  return false;
}