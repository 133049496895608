import { Button, FormControl, FormLabel, Input, InputGroup, InputRightAddon, Select, Stack, useColorModeValue, useToast } from '@chakra-ui/react'
import { ethers } from 'ethers'
import * as React from 'react'
import { getConnectedWallet } from '../../utils/wallet'

import token721ABI from '../../resources/token721ABI.json';
import token1155ABI from '../../resources/token1155ABI.json';
import addresses from '../../resources/addresses.json';
import { openInNewTab } from '../../utils/externalLink';

import marketplaceABI from "../../resources/marketplaceABI.json";
import axios from 'axios';
import { arrayify } from 'ethers/lib/utils';
import { useWeb3React } from '@web3-react/core'

export const SellForm = (props) => {
  const { item, sellTokens, onClose } = props
  const { amount, standard, contractAddress, tokenId } = item
  const { active, library, chainId } = useWeb3React();

  const toast = useToast();
  const toastIdRef = React.useRef()
  const is721 = standard.includes("ERC721")

  const focusBorderColor = useColorModeValue('blue.500', 'blue.200')

  const [sellAmount, setSellAmount] = React.useState(1);
  const [amountValid, setAmountValid] = React.useState(true);
  const [sellToken, setSellToken] = React.useState(sellTokens[0])
  const [sellPrice, setSellPrice] = React.useState(0);
  const [sellPriceValid, setSellPriceValid] = React.useState(true)
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  let sellButtonClickable = true

  const handlePriceChange = (price) => {
    setSellPrice(price)
    if (isNaN(parseFloat(price))) {
      setSellPriceValid(false)
    } else {
      setSellPriceValid(true)
    }
  }

  const handleAmountValue = (value) => {
    const number = parseInt(value);

    if (value === "") {
      setAmountValid(true);
      setSellAmount(0);
      return;
    }

    if (isNaN(number)) {
      setAmountValid(false);
      setSellAmount(0);
      return;
    }

    if (number > amount || number < 0 ) {
      setAmountValid(false);
      setSellAmount(number);
      return;
    }

    setAmountValid(true);
    setSellAmount(number);
  }

  const handleSetSellToken = (name) => {
    for (let i = 0; i < sellTokens.length; i++) {
      if (sellTokens[i].name === name) {
        setSellToken(sellTokens[i])
        break;
      }
    }
  }

  const handleTokenApproval = async (token, operator, is721) => {
    setButtonDisabled(() => true);

    // Create transaction
    try {
      toastIdRef.current = toast({
        title: "Approving BlockInvest marketplace",
        status: 'info',
        duration: 9000,
        isClosable: false,
      })

       

      let tx;
      if (is721) {
        tx = await token.setApprovalForAll(operator, true);
      } else {
        tx = await token.setApprovalForAll(operator, true);
      }

      // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
      // chainId = parseInt(chainId, 16)
      const contractAddresses = addresses[chainId];
      const explorer = contractAddresses.explorer + tx.hash;
      const description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )

      toast.update(toastIdRef.current, { title: "Approving BlockInvest marketplace", description: description })

      await tx.wait()

      toast.update(toastIdRef.current, { title: "BlockInvest marketplace approved", isClosable: true, status: "success" });

      
    } catch (error) {
      // Close all toasts
      toast.closeAll();

      toast({
        title: "Unable to approve BlockInvest marketplace",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.log(error.message)
    }
  }

  const handleSell = async () => {
    // input check
    if (! sellPriceValid) {
      toast({
        title: "Please provide a valid sell price",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      sellButtonClickable = true
      return;
    }

    const price = ethers.utils.parseUnits(sellPrice, sellToken.decimals).toString();

    const amount = parseInt(sellAmount)
    if (isNaN(amount) || ! amountValid) {
      toast({
        title: "Please provide a valid amount",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      sellButtonClickable = true
      return;
    }

    if (! active) {
      toast({
        title: "Connect your wallet",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      sellButtonClickable = true
      return;
    }
    
    if (! sellButtonClickable) {
      return 
    }

    sellButtonClickable = false

    const signer = await library.getSigner();
    const owner = await signer.getAddress()

    setButtonDisabled(() => true);

    // Create contract
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const marketplaceAddress = addresses[chainId].marketplace
    const marketplace = new ethers.Contract(marketplaceAddress, marketplaceABI, signer)

    // Check if marketplace already has approval
    let token;
    if (is721) {
      token = new ethers.Contract(contractAddress, token721ABI, signer);
    } else {
      token = new ethers.Contract(contractAddress, token1155ABI, signer);
    }
    const isApproved = await token.isApprovedForAll(owner, marketplace.address);
    if (! isApproved) {
      await handleTokenApproval(token, marketplace.address, is721)
    }
    
    // create order hash and sign it
    const royalties = 1
    const orderId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) + 1
    const order = (orderId + sellToken.address + price + amount + tokenId + owner + contractAddress + royalties).toLocaleLowerCase()
    const orderHash = ethers.utils.id(order)
    const signature = await signer.signMessage(arrayify(orderHash))

    // call backend
    axios.post(process.env.REACT_APP_API_URL + '/marketplace/order', {
      paymentToken: sellToken.address,
      price: price,
      amount: amount,
      tokenId: tokenId,
      chainId: chainId,
      owner: owner,
      collectionAddress: contractAddress,
      royalties: true,
      orderId: orderId,
      signature: signature
    })
    .then(response => {
      toast({
        title: "Sell order created",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      sellButtonClickable = true

      onClose();
    })
    .catch(error => {
      console.log("Unable to add order to marketplace:", error.message)

      toast({
        title: "Unable to create order",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    })

    sellButtonClickable = true


    // let marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer);
    // const walletAddress = await signer.getAddress();

    // // Check if marketplace already has approval
    // let token;
    // if (is721) {
    //   token = new ethers.Contract(contractAddress, token721ABI, signer);
    // } else {
    //   token = new ethers.Contract(contractAddress, token1155ABI, signer);
    // }

    // const isApproved = await token.isApprovedForAll(walletAddress, marketplace.address);
    // if (! isApproved) {
    //   await handleTokenApproval(token, marketplace.address, is721)
    // }
    
    // // Create transaction
    // try {
    //   toastIdRef.current = toast({
    //     title: "Creating transaction",
    //     status: 'info',
    //     duration: 9000,
    //     isClosable: false,
    //   })

    //   console.log(contractAddress, tokenId, amount, price, sellToken.address)

    //   let tx;
    //   if (is721) {
    //     tx = await marketplace.sell721(contractAddress, BigNumber.from(tokenId), price, sellToken.address);
    //   } else {
    //     tx = await marketplace.sell1155(contractAddress, BigNumber.from(tokenId), BigNumber.from(amount), price, sellToken.address);
    //   }

    //   const explorer = contractAddresses.explorer + tx.hash;
    //   const description = (
    //     <span>
    //       <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
    //     </span>
    //   )

    //   toast.update(toastIdRef.current, { title: "Selling NFT", description: description })

    //   await tx.wait()

    //   toast.update(toastIdRef.current, { title: "NFT is listed in the marketplace", isClosable: true, status: "success" });
    // } catch (error) {
    //   // Close all toasts
    //   toast.closeAll();

    //   toast({
    //     title: "Unable to sell the NFT",
    //     status: 'error',
    //     duration: 9000,
    //     isClosable: true,
    //   });
    //   console.log(error.message)
    // }
  }

  return (
    <Stack
      as="form"
      spacing="3"
      onSubmit={(e) => {
        e.preventDefault() // manage form submission
      }}
    >
      <FormControl id="token">
        <FormLabel>Payment token</FormLabel>

        <Select onChange={(e) => {handleSetSellToken(e.target.value)}}>
          {
            sellTokens.map((token) => (
              <option value={token.name} key={token.name} onClick={() => setSellToken(token)}>{token.name}</option>
            ))
          }
        </Select>
      </FormControl>

      <FormControl id="address">
        <FormLabel>Price</FormLabel>

        <InputGroup maxW={{ md: '3xl' }}>
          <Input placeholder="0.1" value={sellPrice} isInvalid={! sellPriceValid} onChange={(e) => {handlePriceChange(e.target.value)}} />
          <InputRightAddon children={sellToken.name} />
        </InputGroup>
      </FormControl>

      {
        ! is721 && (
          <FormControl id="address">
            <FormLabel>Amount</FormLabel>
            <Input
              type="text"
              placeholder="2"
              size="lg"
              fontSize="md"
              focusBorderColor={focusBorderColor}
              isInvalid={! amountValid}
              onChange={(e) => { handleAmountValue(e.target.value) }}
              value={sellAmount}
            />
          </FormControl>
        )
      }

      <Button
        type="submit"
        marginTop="5"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="md"
        colorScheme="blue"
        size="lg"
        onClick={handleSell}
        isDisabled={buttonDisabled}
      >
        Sell
      </Button>
    </Stack>
  )
}   