import { Button, FormControl, FormLabel, Input, Stack, useColorModeValue, useToast } from '@chakra-ui/react'
import { ethers } from 'ethers'
import * as React from 'react'
import { getConnectedWallet } from '../../utils/wallet'

import token721ABI from '../../resources/token721ABI.json';
import token1155ABI from '../../resources/token1155ABI.json';
import addresses from '../../resources/addresses.json';
import { openInNewTab } from '../../utils/externalLink';
import { useWeb3React } from '@web3-react/core'

export const TransferForm = (props) => {
  const { item } = props
  const { amount, standard, contractAddress, tokenId } = item

  const { library, chainId } = useWeb3React()
  const toast = useToast();
  const toastIdRef = React.useRef()
  const is721 = standard === "ERC721"

  const focusBorderColor = useColorModeValue('blue.500', 'blue.200')

  const [transferAmount, setTransferAmount] = React.useState(0);
  const [amountValid, setAmountValid] = React.useState(true);
  const [recipientAddress, setRecipientAddress] = React.useState("");
  const [recipientValid, setRecipientValid] = React.useState(true);

  const handleRecipientAddress = (address) => {
    if (ethers.utils.isAddress(address) || address === "") {
      setRecipientValid(true);
      setRecipientAddress(address);
      return;
    }

    setRecipientValid(false);
  }

  const handleAmountValue = (value) => {
    const number = parseInt(value);

    if (value === "") {
      setAmountValid(true);
      setTransferAmount(0);
      return;
    }

    if (isNaN(number)) {
      setAmountValid(false);
      setTransferAmount(0);
      return;
    }

    if (number > amount || number < 0 ) {
      setAmountValid(false);
      setTransferAmount(number);
      return;
    }

    setAmountValid(true);
    setTransferAmount(number);
  }

  const handleTransfer = async () => {
    toast.closeAll();

    console.log(recipientAddress);
    console.log(transferAmount);

    if (! amountValid || ! recipientValid || recipientAddress === "" || (! is721 && transferAmount === 0) ) {
       toast({
        title: "Unable to transfer your tokens",
        description: "Please compile all the fields above",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      return;
    }

    const signer = await library.getSigner()
    const owner = await signer.getAddress();

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    try {
      let tx;

      toastIdRef.current = toast({
        title: "Transferring your token",
        description: "Creating transaction",
        status: 'info',
        duration: null,
        isClosable: false,
      })

      if (is721) {
          const token = new ethers.Contract(contractAddress, token721ABI, signer);
          tx = await token.transferFrom(owner, recipientAddress, tokenId);
      } else {
          const token = new ethers.Contract(contractAddress, token1155ABI, signer);
          tx = await token.safeTransferFrom(owner, recipientAddress, tokenId, transferAmount, "0x00");
      }

      const explorerUrl = contractAddresses.explorer + tx.hash;
      const description = (
        <span>
          <a onClick={() => { openInNewTab(explorerUrl); }} href="#/">Click here to view on explorer</a>
        </span>
      )


      toast.update(toastIdRef.current, { title: "Transferring your token", description: description })

      await tx.wait();

      toast.update(toastIdRef.current, { title: "Token transferred", description: description, status: "success", duration: 9000})

  } catch (err) {
    toast.closeAll();
    toast({
      title: "Unable to transfer your token",
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
  }

  return (
    <Stack
      as="form"
      spacing="3"
      onSubmit={(e) => {
        e.preventDefault() // manage form submission
      }}
    >
      <FormControl id="address">
        <FormLabel>Recipient address</FormLabel>
        <Input
          type="text"
          placeholder="0x71C93beF8b2d5cF72Fa9b572bd5D4680775Ce7D6"
          size="lg"
          fontSize="md"
          focusBorderColor={focusBorderColor}
          isInvalid={! recipientValid}
          onChange={(e) => { handleRecipientAddress(e.target.value) }}
          value={recipientAddress}
        />
      </FormControl>

      {
        ! is721 && (
          <FormControl id="address">
            <FormLabel>Amount</FormLabel>
            <Input
              type="text"
              placeholder="2"
              size="lg"
              fontSize="md"
              focusBorderColor={focusBorderColor}
              isInvalid={! amountValid}
              onChange={(e) => { handleAmountValue(e.target.value) }}
              value={transferAmount}
            />
          </FormControl>
        )
      }

      <Button
        type="submit"
        marginTop="5"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="md"
        colorScheme="blue"
        size="lg"
        onClick={handleTransfer}
      >
        Transfer
      </Button>
    </Stack>
  )
}   