import { Box, Flex, HStack, Image, MenuItem, Text } from '@chakra-ui/react'
import * as React from 'react'

export const MenuItemWithLogo = (props) => {
  return (
    <MenuItem onClick={() => {props.handler(props.chainId)}}>
      <HStack spacing="5px">
        <Box boxSize="15px">
          <Image src={props.logo} alt={props.alt} />
        </Box>
        <Text> {props.text}</Text>
      </HStack>
    </MenuItem>
  )
}

export default MenuItemWithLogo