import {
    AspectRatio,
    Box,
    Button,
    HStack,
    Image,
    Link,
    Skeleton,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    Flex,
    Divider,
    Center,
    SimpleGrid,
    VStack,
    Badge,
  } from '@chakra-ui/react'
import * as React from 'react'
import { SellForm } from './SellForm'
import { TransferForm } from './TransferForm'
import validator from 'validator'
import {BsFillPlayFill, BsFillLockFill} from 'react-icons/bs'
import { isVideo } from '../../utils/file'
import { getIconPathFromDocumentUrl, getNameFromDocumentUrl } from '../../utils/documents'

export const CollectionCard = (props) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  const { item, sellTokens, rootProps } = props
  const { metadataName, metadataDescription, metadataImage, amount, standard, canBeSoldOnMarketplace, metadataFields, hasWhitelist, attachedDocuments } = item

  console.log(attachedDocuments)

  const is721 = standard === "ERC721"

  const [modalOpen, setModalOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [sellModalOpenl, setSellModalOpen] = React.useState(false);

  const handleSellCompleted = () => {
    setSellModalOpen(false)
  }

  return (
    <>
    <Stack
      spacing={useBreakpointValue({
        base: '4',
        md: '5',
      })}

      {...rootProps}
    >
      <Box position="relative" maxW={"xs"}>
        <AspectRatio ratio="1" onClick={() => {setDetailOpen(true)}}>
          {
            isVideo(metadataImage) ? (
              <Box 
                as='video'
                src={metadataImage}
                alt={metadataName}
                objectFit='contain'
                sx={{
                  aspectRatio: '1'
                }}
              />
            ) : (
              <Image
                src={metadataImage}
                alt={metadataName}
                draggable="false"
                fallback={<Skeleton />}
                height="100%"
                borderRadius={['xl','md']}
              />
            )
          }
        </AspectRatio>
      </Box>
      <Stack>
        <HStack spacing="1" onClick={() => {setDetailOpen(true)}}>
          <Text fontWeight="medium" maxW="xs" color={useColorModeValue('gray.700', 'gray.400')}>
            { metadataName.length + ( is721 ? 0 : amount.toString().length + 3) > 16 ? metadataName.substring(0, 13 - (is721 ? 0 : amount.toString().length + 3)).trim() + "..." : metadataName} { ! is721 ? "(x" + amount + ")" : ""}
          </Text>
          {
            isVideo(metadataImage) && (
              <Badge ml='1' fontSize='0.8em' colorScheme='blue'>
                <BsFillPlayFill size={16} color='#3182ce' />
              </Badge>
            )
          }
          {
            hasWhitelist && (
              <Badge ml='1' fontSize='0.8em' colorScheme='green'>
                WL
              </Badge>
            )
          }
        </HStack>
        <HStack onClick={() => {setDetailOpen(true)}}>
          <Text fontSize="sm" isTruncated color={useColorModeValue('gray.600', 'gray.400')}>
            {metadataDescription.length > 30 ? metadataDescription.substring(0, 28) + "..." : metadataDescription }
          </Text>
        </HStack>
      </Stack>
      <HStack maxW={"xs"}>
        <Button colorScheme="blue" w={ canBeSoldOnMarketplace ? "50%" : "100%" } onClick={() => {setModalOpen(true)}}>
          Transfer
        </Button>
        
        {
          canBeSoldOnMarketplace && (
            <Button colorScheme="blue" w="50%"  onClick={() => {setSellModalOpen(true)}}>
              Sell
            </Button>
          )
        }
      </HStack>
    </Stack>

    <Modal
      isOpen={detailOpen}
      onClose={() => void 0}
      isCentered
      size={["xs", "5xl"]}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" mx="4">
        <ModalCloseButton size="lg" backgroundColor="gray.50" onClick={() => {setDetailOpen(false)}} mt="6"/>
        <ModalBody padding="0">
          <Flex direction={{ base: 'column', md: 'row'}}>
            {
              isVideo(metadataImage) ? (
                <Box 
                  as='video'
                  controls
                  src={metadataImage}
                  alt={metadataName}
                  objectFit='contain'

                  maxW={{ lg: 'md' }}
                  maxH={["250px", "700px"]}
                  display={{ base: 'flex', md: 'flex' }}
                  my="2"
                />
              ) : (
                <Image
                  src={metadataImage}
                  alt="Asset image"
                  height="100%"
                  objectFit="contain"
                  fallback={<Skeleton />}
                  maxW={{ lg: 'md' }}
                  maxH={["250px", "100%"]}
                  display={{ base: 'flex', md: 'flex' }}
                  my="2"
                />
              )
            }

            <Flex direction="column" align="center" flex="1" pb="5" px={{ base: '4', md: '6' }}  overflowY="scroll" maxH={["400px", "100%"]}>
              <Box maxW="100%" mx="auto">
                <Box textAlign="left" maxW={{ base: 'md', sm: 'xl' }} mx="auto" mt="10">
                  <Text fontSize={{ base: 25, sm: 20}} fontWeight="extrabold">{metadataName}  { ! is721 ? "(x" + amount + ")" : ""}</Text>
                  <Text fontSize={{ base: 18, sm: 15}} mt="2" noOfLines={{ base: 3, md: 10}} style={{whiteSpace: 'normal'}} maxH="100px" overflowY='scroll'>
                    {metadataDescription}
                  </Text>
                  
                  {
                    metadataFields?.length > 0 && (
                      <SimpleGrid  columns={[2, 2, 3]}
                        columnGap={{
                          base: '1',
                          md: '2',
                        }}
                        rowGap={{
                          base: '1',
                          md: '2',
                        }}
                        py="2"
                      >
                        { // show attributes only they are filled
                          metadataFields?.length > 0 && metadataFields.map(attribute => (
                            <>
                              {
                                attribute.value !== "" && (
                                  <Box border='1px' borderRadius="10" borderColor="gray.400" bg="gray.50">
                                    <Center>
                                      <VStack spacing="0" p="3">
                                        <Text isTruncated>{attribute.trait_type}</Text>
                                        {
                                          validator.isURL(attribute.value) ? (
                                            <Link target="_blank" isTruncated fontWeight="semibold" href={attribute.value} >
                                              {attribute.value.length > 15 ? attribute.value.substring(0, 13) + "..." : attribute.value }  
                                            </Link>
                                          ) : (
                                            <Text isTruncated fontWeight="semibold" >
                                              {attribute.value.length > 15 ? attribute.value.substring(0, 13) + "..." : attribute.value }  
                                            </Text>
                                          )
                                        }
                                      </VStack>
                                    </Center>
                                  </Box>
                                )
                              }
                            </>
                          ))
                        }

                      </SimpleGrid>
                    )
                  }

                  {
                    attachedDocuments?.length > 0 && (
                      <>
                      <Divider pt="3"/>
                      <SimpleGrid  columns={[2, 2, 3]}
                        columnGap={{
                          base: '1',
                          md: '2',
                        }}
                        rowGap={{
                          base: '1',
                          md: '2',
                        }}
                        py="2"
                      >
                        { // show attributes only they are filled
                          attachedDocuments.map(document => (
                            <>
                              {
                                document !== "" && (
                                  <Box border='1px' borderRadius="10" borderColor="gray.400" bg="gray.50">
                                    <Center>
                                      <VStack spacing="0" p="3" maxW="100%">
                                        <Image src={getIconPathFromDocumentUrl(document)} maxH="30px" alt="Icon"/> 
                                        <Link href={document} target='_blank' rel="noreferrer" isTruncated 
                                          fontSize={{ base: 18, sm: 15}} mt="2" maxW="100%"
                                        >
                                          { getNameFromDocumentUrl(document) }
                                        </Link>
                                      </VStack>
                                    </Center>
                                  </Box>
                                )
                              }
                            </>
                          ))
                        }

                      </SimpleGrid> 
                      </>
                    )
                  }

                  <Divider pt="3"/>
                  <Text pt="3">Contract: {item.contractAddress}</Text>
                  <Text pt="3">ID: {item.tokenId}</Text>
                </Box>
              </Box>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>

    <Modal
      isOpen={modalOpen}
      onClose={() => { setModalOpen(false)} }
      size="2xl" // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
      blockScrollOnMount={true}
      trapFocus={true}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" mx="4">
        <ModalBody>
          <Stack
            maxW="lg"
            mx="auto"
            py={{
              base: '12',
              md: '16',
            }}
            spacing={{
              base: '6',
              md: '10',
            }}
          >
            <Stack spacing="3" textAlign="center">
              <Text fontSize="lg">You are transferring <Text fontWeight="extrabold">{metadataName}</Text></Text>
            </Stack>
            <TransferForm item={item}/>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>

    <Modal
      isOpen={sellModalOpenl}
      onClose={() => { setSellModalOpen(false)} }
      size="2xl" // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
      blockScrollOnMount={true}
      trapFocus={true}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" mx="4">
        <ModalBody>
          <Stack
            maxW="lg"
            mx="auto"
            py={{
              base: '12',
              md: '16',
            }}
            spacing={{
              base: '6',
              md: '10',
            }}
          >
            <Stack spacing="3" textAlign="center">
              <Text fontSize="lg">You are selling <Text fontWeight="extrabold">{metadataName}</Text></Text>
            </Stack>
            <SellForm item={item} sellTokens={sellTokens} onClose={handleSellCompleted}/>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
    </>
  )
}