import { Button, Divider, Flex, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { InfoPopover } from './InfoPopover'

export const CustomAttribute = (props) => {
  return (
    <Stack spacing="5" >
      <FormControl>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '1.5', md: '8' }}
          justify="space-between"
        >
          <FormLabel variant="inline">
            Name
            {' '} <InfoPopover header="Name" body="Name of the field (for example external_url, animation_url, ...)"/>
          </FormLabel>
          <Input maxW={{ md: '3xl' }} placeholder="height" value={props.field} onChange={(e) => props.updateName(props.id, e.target.value)}/>
        </Stack>
      </FormControl>
      <Flex direction="row-reverse">
        <Button variant="primary" onClick={() => {props.delete(props.id)}}>Delete</Button>
      </Flex>
      <Divider />
    </Stack>
  )
}

export default CustomAttribute