import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Flex,
  useToast,
  Divider,
  Center,
  SimpleGrid,
  VStack,
  Link,
  Badge,
} from '@chakra-ui/react'
import * as React from 'react'

import marketplaceABI from '../../resources/marketplaceABI.json';
import addresses from '../../resources/addresses.json';
import { ethers } from 'ethers'
import { openInNewTab } from '../../utils/externalLink';
import { MarketplaceBuyForm } from './MarketplaceBuyForm';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core'

import erc20ABI from '../../resources/tokenERC20.json'

import validator from 'validator';
import { isVideo } from '../../utils/file';
import { BsFillPlayFill } from 'react-icons/bs';
import { getIconPathFromDocumentUrl, getNameFromDocumentUrl } from '../../utils/documents';

export const MarketplaceCardBuy = (props) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  const toast = useToast();
  const toastIdRef = React.useRef()

  const { library, chainId, account } = useWeb3React()
  const { item, sellTokens, connectedWallet, is721, collection, onUpdate, rootProps } = props
  const { name, description, image, amount, availableAmount, orderId, price, paymentToken, token, hasWhitelist } = item
  const { metadataFields, attachedDocuments } = token

  console.log("item:", item)

  const [decimals, setDecimals] = React.useState(18)
  const [paymentTokenName, setPaymentTokenName] = React.useState("")
  
  let buyButtonClickable = true
  let delistButtonClickable = true

  React.useEffect(() => {
    for (let i = 0; i < sellTokens.length; i++) {
      if (sellTokens[i].address === paymentToken) {
        setDecimals(sellTokens[i].decimals)
        setPaymentTokenName(sellTokens[i].name)
        break;
      }
    }
  }, [sellTokens, paymentToken]) 

  const [detailOpen, setDetailOpen] = React.useState(false);
  const [buy1155Modalopen, setBuy1155ModalOpen] = React.useState(false);

  const buyItem = async (coinAmount) => {
    const signer = await library.getSigner()

    toast.closeAll()
    toastIdRef.current = toast({
      title: "Buy NFT",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    try {
      // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
      // chainId = parseInt(chainId, 16)
      const contractAddresses = addresses[chainId];

      const marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer);
      console.log(marketplace.address)
      
      // create order
      console.log("order id:", item.orderId)
      const orderStruct = [item.orderId, item.paymentToken, item.price, item.amount, item.tokenId, item.owner, item.collectionAddress, item.hasRoyalties];

      let tx = await marketplace.buy721(orderStruct, item.signature, {value: coinAmount});

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )

      toast.update(toastIdRef.current, { title: "Buying NFT", description: description })

      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "NFT bought", description: description, status: 'success', duration: 9000});
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to buy the NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
      return;
    }

    await new Promise(res => setTimeout(res, 5000));
    onUpdate().then();
  }

  const handleBuyWithNative = async () => {
    await buyItem(price)
    buyButtonClickable = true
  }

  const handleBuyWithToken = async () => {
    const signer = await library.getSigner()

    toastIdRef.current = toast({
      title: "Payment approval",
      description: "You have to approve the payment.",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    try {
      // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
      // chainId = parseInt(chainId, 16)
      const contractAddresses = addresses[chainId];
      const token = new ethers.Contract(paymentToken, erc20ABI, signer);

      const allowance = await token.allowance(account, contractAddresses.marketplace);

      if (allowance < price) {
        let tx = await token.approve(contractAddresses.marketplace, price);

        // update for the transaction to be completed
        const explorer = contractAddresses.explorer + tx.hash;
        let description = (
          <span>
            <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
          </span>
        )

        toast.update(toastIdRef.current, { title: "Approving payment", description: description })

        await tx.wait();
        
        // wait 3 seconds
        await new Promise(res => setTimeout(res, 3000))

        // update for the transaction to be completed
        toast.update(toastIdRef.current, { title: "Payment approved", description: description, status: 'success', duration: 9000});
      }

    } catch (error) {
      toast.closeAll();
      toast({
        title: "Insufficient funds",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
      return;
    }

    await buyItem(0)

    buyButtonClickable = true;
  }

  const handleBuy = async () => {
    if (! buyButtonClickable) {
      return 
    }

    if (! is721) {
      setBuy1155ModalOpen(true)
      return;
    }

    buyButtonClickable = false;
    if (paymentToken === ethers.constants.AddressZero) {
      handleBuyWithNative();
    } else {
      handleBuyWithToken();
    }
  }

  const handleDelist = async () => {
    if (! delistButtonClickable) {
      return 
    }

    delistButtonClickable = false
    const signer = await library.getSigner()

    // toastIdRef.current = toast({
    //   title: "Delist NFT",
    //   description: "Creating transaction",
    //   status: 'info',
    //   duration: null,
    //   isClosable: false,
    // })

    // try {
    //   let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    //   chainId = parseInt(chainId, 16)
    //   const contractAddresses = addresses[chainId];

    //   const marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer);
      
    //   let tx;
    //   if (is721) {
    //     tx = await marketplace.cancelSell721(orderId);
    //   } else {
    //     tx = await marketplace.cancelSell1155(orderId);
    //   }

    //   // update for the transaction to be completed
    //   const explorer = contractAddresses.explorer + tx.hash;
    //   let description = (
    //     <span>
    //       <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
    //     </span>
    //   )

    //   toast.update(toastIdRef.current, { title: "Delisting NFT", description: description })

    //   await tx.wait();

    //   // update for the transaction to be completed
    //   toast.update(toastIdRef.current, { title: "NFT delisted", description: description, status: 'success', duration: 9000});
    // } catch (error) {
    //   toast.closeAll();
    //   toast({
    //     title: "Unable to delist the NFT",
    //     status: 'error',
    //     duration: 9000,
    //     isClosable: true,
    //   });

    //   console.log(error.message);
    //   return;
    // }

    const signature = await signer.signMessage(item.signature)
    const owner = await signer.getAddress();

    axios.delete(process.env.REACT_APP_API_URL + '/marketplace/order', {
      data: {
        orderSignature: item.signature,
        signature: signature,
        owner: owner
      }
    })
    .then((response) => {
      toast({
        title: "NFT Delisted",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      onUpdate().then()
      delistButtonClickable = true
    })
    .catch (error => {
      toast({
        title: "Unable to delist NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      delistButtonClickable = true
    })
  }

  return (
    <>
    <Stack
      spacing={useBreakpointValue({
        base: '4',
        md: '5',
      })}
      {...rootProps}
    >
      <Box position="relative" maxW={"xs"}>
        <AspectRatio ratio="1" onClick={() => {setDetailOpen(true)}}>
        {
          isVideo(image) ? (
            <Box 
              as='video'
              src={image}
              alt={name}
              objectFit='contain'
              sx={{
                aspectRatio: '1'
              }}
            />
          ) : (
            <Image
              src={image}
              alt={name}
              draggable="false"
              fallback={<Skeleton />}
              height="100%"
              borderRadius={['xl','md']}
            />
          )
        }
        </AspectRatio>
      </Box>
      <Stack>
        <HStack spacing="1" onClick={() => {setDetailOpen(true)}}>
          <Text fontWeight="medium" color={useColorModeValue('gray.700', 'gray.400')}>
            {name.length > 20 ? name.substring(0, 18).trim() + "..." : name}
            { ! is721 ? "(x" + item.availableAmount + ")" : ""}
          </Text>

          {
            isVideo(image) && (
              <Badge ml='1' fontSize='0.8em' colorScheme='blue'>
                <BsFillPlayFill size={16} color='#3182ce' />
              </Badge>
            )
          }

          {
            hasWhitelist && (
              <Badge ml='1' fontSize='0.8em' colorScheme='green'>
                WL
              </Badge>
            )
          }
        </HStack>
        <HStack onClick={() => {setDetailOpen(true)}}>
          <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')} >
            {description.length > 30 ? description.substring(0, 28) + "..." : description }
          </Text>
        </HStack>
      </Stack>
      <HStack maxW={"xs"}>
        <Button colorScheme="blue" w="50%" onClick={() => {setDetailOpen(true)}}>
          Show details
        </Button>
        
        {
          connectedWallet !== item.owner && (
            <Button colorScheme="blue" w="50%" onClick={handleBuy} disabled={! buyButtonClickable}>
              Buy
            </Button>
          )
        }

        {
          connectedWallet === item.owner && (
            <Button colorScheme="red" w="50%" onClick={handleDelist} >
              Delist
            </Button>
          )
        }
      </HStack>
    </Stack>

    <Modal
      isOpen={detailOpen}
      onClose={() => void 0}
      size={["xs", "5xl"]}
      // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
      blockScrollOnMount={true}
      trapFocus={true}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" mx="4" >
        <ModalCloseButton size="lg" backgroundColor="gray.50" onClick={() => {setDetailOpen(false)}} mt="8"/>
        <ModalBody padding="0">
        <Flex direction={{ base: 'column', md: 'row'}}>
          {
            isVideo(image) ? (
              <Box 
                as='video'
                controls
                src={image}
                alt={name}
                objectFit='contain'

                maxW={{ lg: 'md' }}
                maxH={["250px", "700px"]}
                display={{ base: 'flex', md: 'flex' }}
                my="2"
              />
            ) : (
              <Image
                src={image}
                alt="Asset image"
                height="100%"
                objectFit="contain"
                fallback={<Skeleton />}
                maxW={{ lg: 'md' }}
                maxH={["250px", "100%"]}
                display={{ base: 'flex', md: 'flex' }}
                my="2"
              />
            )
          }
            <Flex direction="column" align="center" flex="1" mb='6' px={{ base: '4', md: '6' }} maxH={["400px", "100%"]} overflowY="scroll">
              <Box maxW="100%" mx="auto" pb={["0", "6"]}>
                <Box textAlign="left" maxW={{ base: 'xl', sm: 'md' }} mx="auto" mt="10">
                  <Text fontSize={isDesktop ? 35 : 20} fontWeight="extrabold">{name}  { ! is721 ? "(x" + availableAmount + ")" : ""}</Text>
                  <Text fontSize={isDesktop ? "lg" : "md"} mt="2" style={{whiteSpace: 'normal'}} maxH="100px" overflowY='scroll'>
                    {description}
                  </Text>
                  <SimpleGrid columns={[2, 2, 3]}
                    columnGap={{
                      base: '1',
                      md: '2',
                    }}
                    rowGap={{
                      base: '1',
                      md: '2',
                    }}
                    py="2"
                  >
                    {
                      metadataFields.map(field => (
                        <>
                        {
                          field.value !== "" && (
                            <Box border='1px' borderRadius="10" borderColor="gray.400" bg="gray.50">
                              <Center>
                                <VStack spacing="0" p="3" w="100%">
                                  <Text noOfLines={1} maxW="95%" isTruncated>{field.trait_type}</Text>
                                  {
                                    validator.isURL(field.value) ? (
                                      <Link target="_blank" isTruncated fontWeight="semibold" href={field.value} >
                                        {field.value.length > 15 ? field.value.substring(0, 13) + "..." : field.value }  
                                      </Link>
                                    ) : (
                                      <Text isTruncated fontWeight="semibold" >
                                        {field.value.length > 15 ? field.value.substring(0, 13) + "..." : field.value }  
                                      </Text>
                                    )
                                  }
                                </VStack>
                              </Center>
                            </Box>
                          )
                        }
                        </>
                      ))
                    }

                  </SimpleGrid>

                  {
                    attachedDocuments?.length > 0 && (
                      <>
                      <Divider pt="3"/>
                      <SimpleGrid  columns={[2, 2, 3]}
                        columnGap={{
                          base: '1',
                          md: '2',
                        }}
                        rowGap={{
                          base: '1',
                          md: '2',
                        }}
                        py="2"
                      >
                        { // show attributes only they are filled
                          attachedDocuments.map(document => (
                            <>
                              {
                                document !== "" && (
                                  <Box border='1px' borderRadius="10" borderColor="gray.400" bg="gray.50">
                                    <Center>
                                      <VStack spacing="0" p="3" maxW="100%">
                                        <Image src={getIconPathFromDocumentUrl(document)} maxH="30px" alt="Icon"/> 
                                        <Link href={document} target='_blank' rel="noreferrer" isTruncated 
                                          fontSize={{ base: 18, sm: 15}} mt="2" maxW="100%"
                                        >
                                          { getNameFromDocumentUrl(document) }
                                        </Link>
                                      </VStack>
                                    </Center>
                                  </Box>
                                )
                              }
                            </>
                          ))
                        }

                      </SimpleGrid> 
                      </>
                    )
                  }

                  <Divider pt="3"/>
                  {/* <Text pt="3" fontWeight="semibold">Price: <Text as="span" color="blue.600">{ethers.utils.formatUnits(price.toString(), decimals)} {paymentTokenName}</Text></Text> */}
                  <Text pt="3" fontWeight="semibold">Price: <Text as="span" color="blue.600">{ethers.utils.formatUnits(price, decimals)} {paymentTokenName}</Text></Text>
                  <Text pt="3">Contract: {collection}</Text>
                  <Text pt="3">ID: {item.tokenId}</Text>
                  <Text pt="3">Owner: {item.owner}</Text>
                </Box>
              </Box>

              {
                connectedWallet !== item.owner && (
                  <Button colorScheme="blue" onClick={handleBuy} disabled={! buyButtonClickable}>
                    Buy
                  </Button>
                )
              }

              {
                connectedWallet === item.owner && (
                  <Button colorScheme="red" onClick={handleDelist} >
                    Delist
                  </Button>
                )
              }
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>

    <Modal
      isOpen={buy1155Modalopen}
      onClose={() => void 0}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" mx="4">
        <ModalCloseButton size="lg" onClick={() => {setBuy1155ModalOpen(false)}}/>
        <ModalBody padding="0">
        <Stack
            maxW="lg"
            mx="auto"
            py={{
              base: '6',
              md: '10',
            }}
            spacing={{
              base: '6',
              md: '10',
            }}
            px={{
              base: '3',
              md: '0',
            }}
          >
            <Stack spacing="3" textAlign="center">
              <Text fontSize="lg">You are buying <Text fontWeight="extrabold">{name}</Text></Text>
            </Stack>
            <MarketplaceBuyForm item={item} onUpdate={onUpdate} />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
    </>
  )
}