import * as React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'
import '@fontsource/inter/variable.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Deployer from './pages/Deployer';
import Manager from './pages/Manager';
import Collection from './pages/Collection';
import Marketplace from './pages/Marketplace'
import { Auth0Provider } from '@auth0/auth0-react'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import Metadata from './pages/Metadata'

window.Buffer = window.Buffer || require("buffer").Buffer; 


function App() {
  const customTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.blue },
    },
    theme,
  )

  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/"}
      scope="read:current_user"
    >
      <ChakraProvider theme={customTheme}>
        <Web3ReactProvider getLibrary={getLibrary} libraryName='ethers.js'>
        <>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/create" element={<Deployer />} />
              <Route path="/metadata" element={<Metadata />} />
              <Route path="/manage" element={<Manager />} />
              <Route path="/" element={<Collection />} />
              <Route path="/marketplace" element={<Marketplace />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </>
        </Web3ReactProvider>
      </ChakraProvider>
    </Auth0Provider>
  )
}

export default App;