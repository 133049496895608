import {
    Button,
    Center,
    HStack,
    Icon,
    Square,
    Text,
    useColorModeValue,
    VStack,
  } from '@chakra-ui/react'
  import React from 'react'
  import { FiUploadCloud } from 'react-icons/fi'
  
export const Dropzone = (props) => {
  const inputFile = React.useRef(null);
  const [dropZoneText, setDropZoneText] = React.useState(props.initialText || 'PNG, JPG, GIF or MP4 up to 1GB')
  const [acceptedExtensions, setAcceptedExtensions] = React.useState(props.accepted || ".png,.jpg,.jpeg,.gif,.mp4");

  const handleDrag = (e) => {
		e.preventDefault();
  		e.stopPropagation();
	}

	const handleDragIn = (e) => {
		e.preventDefault();
  		e.stopPropagation();
	}

	const handleDragOut = (e) => {
		e.preventDefault();
  		e.stopPropagation();
	}

  const handleDrop = (e) => {
		e.preventDefault();
  		e.stopPropagation();

		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			updateFiles(e.dataTransfer.files);
			e.dataTransfer.clearData();
		}
	}

  const handleInputClick = (e) => {
		updateFiles(e.target.files);
	}

	const updateFiles = (files) => {
		let filesArray = [];
    let names = "";

		for (let i = 0; i < Object.keys(files).length; i++) {
			filesArray.push(files[i]);

      if (files[i].name.length + names.length <= 50) {
        if (names !== "") {
          names += ', ';
        }

        names += files[i].name;
      }

      setDropZoneText("Uploading " + names);
		}

		props.updateFiles(filesArray);
	}

	const handleDragAndDropClick = () => {
		// `current` points to the mounted file input element
	  inputFile.current.click();
	};

  return (
    <Center 
      borderWidth="1px"
      borderRadius="lg"
      px="6"
      py="4"
      bg={useColorModeValue('white', 'gray.800')}
      onDragEnter={handleDragIn} 
      onDragLeave={handleDragOut} 
      onDragOver={handleDrag} 
      onDrop={handleDrop}
      {...props}
    >
      <VStack spacing="3" >
        <Square size="10" bg="bg-subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            <Button variant="link" colorScheme="blue" size="sm" onClick={handleDragAndDropClick} >
              Click to upload
            </Button>
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept={acceptedExtensions} multiple onChange={handleInputClick}/>
            <Text fontSize="sm" color="muted">
              or drag and drop
            </Text>
          </HStack>
          <Text fontSize="xs" color="muted">
            {dropZoneText}
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
}