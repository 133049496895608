import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMenu } from 'react-icons/fi'
import { BsChevronDown } from 'react-icons/bs'
import { RiErrorWarningLine } from 'react-icons/ri'
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import MenuItemWithLogo from './MenuItemWithLogo';
import Ethereum from '../resources/ethereum.png';
import Polygon from '../resources/polygon.png';
import Logo from '../resources/logo.png';
import addresses from '../resources/addresses.json';
import factory721ABI from '../resources/factory721ABI.json';
import factory1155ABI from '../resources/factory1155ABI.json';
import { ethers } from 'ethers';
import WalletsModal from './WalletsModal'
import { useWeb3React } from '@web3-react/core'

const NavItem = (props) => (
  <Box as="a" href="#" fontSize="sm" {...props} />
)

const Navbar = () => {
  const navigate = useNavigate();

  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, user} = useAuth0();;
  const { active, chainId, account, library } = useWeb3React();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  // States
  const [showResponsiveNavbar, setShowResponsiveNavbar] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Connect wallet");
  const [networkLogo, setNetworkLogo] = React.useState(Polygon);
  const [networkName, setNetworkName] = React.useState("Amoy");
  const [roleId, setRoleId] = React.useState(3); // 0 = admin, 1 = owner, 2 = generic user, 3 = not connected
  const [invalidNetwork, setInvalidNetwork] = React.useState(false);
  const [navbarItemIndexOffset, setNavbarItemIndexOffset] = React.useState(0)

  let changingRole = false

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  const links = [
    {
      name: "Metadata",
      ref: "/metadata",
      index: 0,
      neededRole: 0
    },
    {
      name: "Create",
      ref: "/create",
      index: 1,
      neededRole: 0
    },
    {
      name: "Manage",
      ref: "/manage",
      index: 2,
      neededRole: 1
    },
    {
      name: "Marketplace",
      ref: "/marketplace",
      index: 3,
      neededRole: 2
    },
    {
      name: "Collection",
      ref: "/",
      index: 4,
      neededRole: 2
    }
  ]

  // Effect
  React.useEffect(() => {
    const ref = window.location.href;
    let offset = roleId !== 3 ? roleId : 0

    if (! isAuthenticated) {
      offset = 3
    }

    setNavbarItemIndexOffset(offset)

    if (ref.includes('metadata')) {
      setCurrentTabIndex(0 - offset);
    } else if (ref.includes('create')) {
      setCurrentTabIndex(1 - offset);
    } else if (ref.includes('manage')) {
      setCurrentTabIndex(2 - offset);
    } else if (ref.includes('marketplace')) {
      setCurrentTabIndex(3 - offset);
    } else {
      setCurrentTabIndex(4 - offset)
    }

    if (active) {
      if (chainId === 80002) {
        setInvalidNetwork(false)
        setNetworkLogo(Polygon);
        setNetworkName("Amoy")
      } else if (chainId === 137) {
        setInvalidNetwork(false)
        setNetworkLogo(Polygon);
        setNetworkName("Polygon")
      } else  {
        setInvalidNetwork(true)
        setNetworkName("Invalid Network")
        setNetworkLogo(null)
        return;
      }
    }

    if (account) {
      setButtonText("Connected: " + account.substring(0, 6) + "..." + account.substring(38))
    } else {
      setButtonText("Connect Wallet")
    }

    updateRole().then()
  }, [active, chainId, account])

  // Get and save access token from auth0
  React.useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `${domain}/api/v2/`,
          scope: "read:current_user",
        });

        window.localStorage.setItem("accessToken", accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);


  const updateRole = async () => {
    if (changingRole || !active) {
      return roleId;
    }

    if (invalidNetwork || !active) {
      setRoleId(2)
      return
    }

    if (window.location.pathname !== "/" && window.location.pathname !== "/marketplace") {
      navigate("/")
      setCurrentTabIndex(3)
    }

    changingRole = true

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    const signer = await library.getSigner()
    let walletAddress = account

    setRoleId(2)
    const factory721 = new ethers.Contract(contractAddresses.factory721, factory721ABI, signer);
    const factory1155 = new ethers.Contract(contractAddresses.factory1155, factory1155ABI, signer);

    // check admin role
    const admin721 = await factory721.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);
    const admin1155 = await factory1155.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);

    if (window.localStorage.getItem("accessToken")) {
      if (admin721 || admin1155) {
        setRoleId(0)
        return
      }

      setRoleId(1)
      return
    }

    changingRole = false
    setRoleId(2)
  }

  const handleConnectWalletButton = async () => {
    if (! active) {
      onOpen()
    }
  }

  const handleLogout = () => {
    window.localStorage.removeItem("accessToken")
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const handleNetworkChange = async (targetChainId) => {
    if (! [137, 80002].includes(targetChainId)) {
      setInvalidNetwork(true)
      setNetworkName("Invalid Network")
      setNetworkLogo(null)
      return;
    }

    try {
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(targetChainId).toString(16)}` }],
      });

      // Change logo and network name
      if (targetChainId === 80002) {
        setNetworkLogo(Polygon);
        setNetworkName("Amoy")
      } else if (targetChainId === 137) {
        setNetworkLogo(Polygon);
        setNetworkName("Polygon")
      } 
    }
    catch (error) {
      console.log("Metamask chain error:", error.message);
    }
  }

  const handleAuth0Login = async () => {
    loginWithRedirect({ prompt: 'consent' }).then().catch(async error => {
      handleLogout()
    })
  }

  return (
    <>
    <Box
      as="section"
      pb={{
        base: '12',
        md: '24',
      }}
    >
      <Box as="nav" bg="bg-surface">
        <Container
          py={{
            base: '4',
            lg: '5',
          }}
        >
          <HStack spacing="10" justify="space-between">
            <Box>
              <Image src={Logo} alt="BlockInvest NFT" maxH="60px" />
            </Box>
            {isDesktop ? (
              <>
                <Tabs colorScheme="blue" isFitted index={currentTabIndex} variant="unstyled">
                  <TabList>
                    {links.map((item) => {
                      if (roleId <= item.neededRole)  return (
                      <Link as={ReachLink} to={item.ref}>
                        <Tab
                          key={item.name}
                          fontSize="md"
                          _selected={{
                            color: 'accent',
                          }}
                          id={item.index}
                          onClick={() => {setCurrentTabIndex(item.index - navbarItemIndexOffset)}}
                        >
                          {item.name}
                        </Tab>
                      </Link>
                    )})
                  }
                  </TabList>
                </Tabs>
                <HStack spacing="3">

                {
                  active && (
                    <>
                      {
                        invalidNetwork && (
                          <Icon as={RiErrorWarningLine} color="red.500" boxSize={8}/>
                        )
                      }
                      <Menu>
                        <MenuButton as={Button} rightIcon={<BsChevronDown />}>
                          <HStack spacing="5px">
                            {
                              networkLogo && (
                              <Box boxSize="15px">
                                <Image src={networkLogo} alt="Logo" />
                              </Box>
                              )
                            }
                            <Text>{networkName}</Text>
                          </HStack>
                        </MenuButton>
                        <MenuList>
                          <MenuItemWithLogo logo={Polygon} alt="Polygon" text="Polygon" handler={handleNetworkChange} chainId={137} />
                          <MenuItemWithLogo logo={Ethereum} alt="Ethereum" text="Goerli" handler={handleNetworkChange} chainId={5} />
                          <MenuItemWithLogo logo={Polygon} alt="Amoy" text="Amoy" handler={handleNetworkChange} chainId={80002} />
                        </MenuList>
                      </Menu>
                  </>
                )}
                  <Button variant="primary" onClick={handleConnectWalletButton}>{buttonText}</Button>
                  {
                    ! isAuthenticated && (
                      <Button variant="primary" onClick={handleAuth0Login}>Sign in</Button>
                    )
                  }
                  {
                    isAuthenticated && (
                      <Button variant="primary" onClick={handleLogout}>Log out</Button>
                    )
                  }

                </HStack>
              </>
            ) : (
              <IconButton
                variant="ghost"
                icon={<FiMenu fontSize="1.25rem" />}
                aria-label="Toggle menu"
                onClick={() => {setShowResponsiveNavbar(! showResponsiveNavbar);}}
              />
            )}
          </HStack>
        </Container>
      </Box>

      <Flex
        hidden={!showResponsiveNavbar || isDesktop}
        justify="center"
        align="center"
        aria-label="Submenu"
        bg="bg-surface"
        height="32"
        borderBottomWidth="1px"
        position
        width="100%"
        top="4.5rem"
      >
        <VStack spacing="5">
          <HStack spacing="10" overflowX='scroll' maxW='80%'>
              {links.map((item) => {
                if (roleId <= item.neededRole)  return (
                <Link as={ReachLink} to={item.ref}>
                  <NavItem fontWeight="semibold" color={currentTabIndex === item.index ? 'accent' : ''} onClick={() => {setCurrentTabIndex(item.index - roleId)}}>{item.name}</NavItem>
                </Link>
                )
              })}
          </HStack>

          <Button variant="primary" onClick={handleConnectWalletButton}>{buttonText}</Button>

          {
            ! isAuthenticated && (
              <Button variant="primary" onClick={loginWithRedirect}>Sign in</Button>
            )
          }

          {
            isAuthenticated && (
              <Button variant="primary" onClick={logout}>Log out</Button>
            )
          }
        </VStack>

      </Flex>

      <Divider />
    </Box>

          
    <WalletsModal isOpen={isOpen} onClose={onClose}/>
    </>
  )
}

export default Navbar