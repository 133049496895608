import { Box, Center, Image, Modal, ModalContent, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import { useWeb3React } from '@web3-react/core'
import MetamaskLogo from '../resources/metamask.png'
import CoinbaseLogo from '../resources/coinbase.png'
import WalletConnectLogo from '../resources/WalletConnectLogo.png'
import { InjectedWallet, WalletConnect, CoinbaseWallet } from "../utils/wallet"

export const WalletsModal = (props) => {
  const { isOpen, onClose, onConnect } = props
  const { active, activate, deactivate } = useWeb3React();

  const handleWalletConnect = async (wallet) => {
    onClose();

    if (active) {
      deactivate();
    }

    await activate(wallet, undefined, true)

    if (onConnect) {
      onConnect().then()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['sm', 'md']} blockScrollOnMount={true} isCentered >
      <ModalOverlay />
      <ModalContent>
       <VStack spacing={4} p="3">

        <Box _hover={{ cursor: 'pointer' }} p="6" border='1px' borderColor='gray.200' w="100%" borderRadius='10px' onClick={() => { handleWalletConnect(InjectedWallet) }}>
          <Center flexDirection='column'>
            <Image src={MetamaskLogo} maxW="100px" alt="Metamask Logo" />
            <Text textAlign='center'>Metamask</Text>
          </Center>
        </Box>

        <Box _hover={{ cursor: 'pointer' }} p="6" border='1px' borderColor='gray.200' w="100%" borderRadius='10px' onClick={() => { handleWalletConnect(WalletConnect) }}>
          <Center flexDirection='column'>
            <Image src={WalletConnectLogo} maxW="100px" alt="Wallet Connect Logo" />
            <Text textAlign='center'>Wallet Connect</Text>
          </Center>
        </Box>

        <Box _hover={{ cursor: 'pointer' }} p="6" border='1px' borderColor='gray.200' w="100%" borderRadius='10px' onClick={() => { handleWalletConnect(CoinbaseWallet) }}>
          <Center flexDirection='column'>
            <Image src={CoinbaseLogo} maxW="100px" alt="Coinbase Wallet Logo" />
            <Text textAlign='center'>Coinbase Wallet</Text>
          </Center>
        </Box>

       </VStack>
      </ModalContent>
    </Modal>
  )
}

export default WalletsModal