import axios from 'axios';
// const fileReaderStream = require('filereader-stream');

export const pinJSONToIPFS = async (JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            return {
                success: false,
                message: error.message,
            }
           
        });
};

export const pinMetadataToIPFS = async (blobs, collectionName) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    //we gather the files from a local directory in this example, but a valid readStream is all that's needed for each file in the directory.
    // recursive.readdirr(src, function (err, dirs, files) {
    let data = new FormData();
    for (let i = 0; i < blobs.length; i++) {
        data.append(`file`, blobs[i], 'BlockInvest/' + i.toString());
    }

    const metadata = JSON.stringify({
        name: collectionName,
        keyvalues: {
            name: 'Name',
            description: 'Description',
            image: 'ipfs://...'
        }
    });
    data.append('pinataMetadata', metadata);

    let resp;
    try {
        resp = await axios.post(url, data, {
            maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large directories
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET
            }
        });
    } catch (error) {
        console.log(error.message);
        return null;
    }

    console.log(resp.data);
    
    return resp.data.IpfsHash;
};

export const pinImageToIPFS = async (image) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    let data = new FormData();
    data.append(`file`, image);

    let resp;
    try {
        resp = await axios.post(url, data, {
            // maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large directories
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET
            }
        })
    } catch (error) {
        console.log(error.message);
        return null;
    }

    return resp.data.IpfsHash;
}