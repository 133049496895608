import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  Icon,
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

export const InfoPopover = (props) => {
  return (
    <>
      <Popover isLazy trigger="hover">
        <PopoverTrigger >
          <InfoIcon size='sm'/>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">{props.header}</PopoverHeader>
          <PopoverBody>{props.body}</PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
