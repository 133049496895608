import { ethers } from 'ethers';

import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
// import { useWeb3React } from '@web3-react/core'

export const CoinbaseWallet = new WalletLinkConnector({
 url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
 appName: "Web3-react Demo",
});

export const WalletConnect = new WalletConnectConnector({
 rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
 bridge: "https://bridge.walletconnect.org",
 qrcode: true,
});

export const InjectedWallet = new InjectedConnector({ });

// export const connectWallet = async () => {
//     if (window.ethereum) {
//         try {
//             const provider = new ethers.providers.Web3Provider(window.ethereum);
//             await provider.send("eth_requestAccounts", []);
//             const signer = await provider.getSigner();

//             return {
//                 status: "Compile fields above to mint your NFT",
//                 provider: provider,
//                 signer: signer
//             }

//         } catch (error) {
//             return {
//                 status: error.message,
//                 provider: null,
//                 signer: null
//             }
//         }
        
//     }
//     // Metamask not installed
//     else {
//         return {
//             status: "Install Metamask",
//             provider: null,
//             signer: null,
//         }
//     }
// }

// export const getConnectedWallet = async () => {
//     const { library } = useWeb3React()
//     const signer = await library.getSigner();

//     if (signer) {
//       return {
//         status: "Compile fields above to mint your NFT",
//         provider: library,
//         signer: signer
//       }
//     }

//     return {
//       status: "Connect your wallet",
//       provider: null,
//       signer: null
//     }
// }