import * as React from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  StackDivider,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Center,
  Image,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { useStep } from '../components/progress/UseStep'
import ReactDatePicker from 'react-datepicker'
import { ethers } from 'ethers';
import Logo from '../resources/logo.png';

import "react-datepicker/dist/react-datepicker.css";
import "../styles/datepicker.css";

import factory721ABI from '../resources/factory721ABI.json';
import factory1155ABI from '../resources/factory1155ABI.json';
import addresses from '../resources/addresses.json';
import { openInNewTab } from '../utils/externalLink'
import { InfoPopover } from '../components/InfoPopover'

import { useAuth0 } from '@auth0/auth0-react'
import { useWeb3React } from '@web3-react/core'
import { BsChevronBarDown } from 'react-icons/bs';
import axios from 'axios';
import { getTomorrowDate } from '../utils/standards';

function Deployer() {
  const { active, library, account, chainId } = useWeb3React()

  // Constants
  const tokenTypes = [
    {
      type: "ERC721",
      code: "ERC721"
    },
    {
      type: "Whitelisted ERC721",
      code: "ERC721WL"
    },
    {
      type: "ERC1155",
      code: "ERC1155"
    },
    {
      type: "Whitelisted ERC1155",
      code: "ERC1155WL"
    },
  ]

  // States
  const { isAuthenticated } = useAuth0();

  const toast = useToast();
  const toastIdRef = React.useRef()

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  // confirmation dialog
  const { isOpen, onOpen, onClose } = useDisclosure();
  const confirmRef = React.useRef()

  const [tokenType, setTokenType] = React.useState('ERC721');
  const [currentChain, setCurrentChain] = React.useState('Polygon');
  const [isConnected, setIsConnected] = React.useState(false);

  const [tokenName, setTokenName] = React.useState('');
  const [tokenSymbol, setTokenSymbol] = React.useState('');
  const [creatorAddress, setCreatorAddress] = React.useState(account || '');
  const [feeCollectorAddress, setFeeCollectorAddress] = React.useState('');
  const [blockInvestFee, setBlockInvestFee] = React.useState('');
  const [blockInvestFeeValid, setBlockInvestFeeValid] = React.useState(true);
  const [creatorFee, setCreatorFee] = React.useState('');
  const [creatorFeeValid, setCreatorFeeValid] = React.useState(true);
  const [mintStartDate, setMintStartDate] = React.useState(getTomorrowDate());
  const [mintPrice, setMintPrice] = React.useState('');
  const [mintPriceValid, setMintPriceValid] = React.useState(true);

  const [metadatas, setMetadatas] = React.useState([])
  const [activeMetadata, setActiveMetadata] = React.useState(null)

  // Effect
  React.useEffect(() => {
    if (active) {
      getRole().then();
      getMetadata().then();
    }
  }, [active, account, chainId])

  const getMetadata = async () => {
    if (! active) {
      setActiveMetadata(null);
      setMetadatas([])
      return 
    }

    const response = await axios.get(process.env.REACT_APP_API_URL + '/metadata/?owner=' + account)
    if (response.status !== 200) {
      setActiveMetadata(null);
      setMetadatas([])
      return
    }

    const newMetadatas = response.data.metadatas;
    if (newMetadatas.length === 0) {
      setActiveMetadata(null);
      setMetadatas([])
      return
    }

    setMetadatas(newMetadatas)
    // setActiveMetadata(newMetadatas[0])
  }

  const getRole = async () => {
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    const signer = await library.getSigner()
    let walletAddress;
    
    try {
      walletAddress = await signer.getAddress();
    } catch (e) {
      setIsConnected(false);
      return;
    }

    const factory721 = new ethers.Contract(contractAddresses.factory721, factory721ABI, signer);
    const factory1155 = new ethers.Contract(contractAddresses.factory1155, factory1155ABI, signer);

    // check admin role
    const admin721 = await factory721.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);
    const admin1155 = await factory1155.hasRole("0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775", walletAddress);

    if (admin721 || admin1155) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }

  // Handlers
  const handleCreatorFeeField = (value) => {
    const isNumber = /^\d+\.\d+$/.test(value) || /^\d+$/.test(value);

    // Check that it's a number
    if (! isNumber) {
      setCreatorFee(value);
      setCreatorFeeValid(false);
      return
    }

    const fee = parseFloat(value)

    // Check ranges
    if (fee < 0.0 || fee > 10.0) {
      setCreatorFeeValid(false);
    } else {
      setCreatorFeeValid(true);
    }
    
    setCreatorFee(value);
  }

  const handleMintPriceField = (value) => {
    const isNumber = /^\d+\.\d+$/.test(value) || /^\d+$/.test(value);

    // Check that it's a number
    if (! isNumber) {
      setMintPrice(value);
      setMintPriceValid(false);
      return
    }

    const price = parseFloat(value)

    // Check ranges
    if (price <= 0.0) {
      setMintPriceValid(false);
    } else {
      setMintPriceValid(true);
    }
    
    setMintPrice(value);
  }

  const errorToast = (text) => {
    toast({
      title: 'Something went wrong',
      description: text,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  const successToast = (title, description) => {
    toast({
      title: title,
      description: description,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const infoToast = (title, description) => {
    toastIdRef.current = toast({
      title: title,
      description: description,
      status: 'info',
      duration: null,
      isClosable: false,
    })
  }

  const updateInfoToast = (title, description) => {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, { title: title, description: description })
    }
  }

  // Deploy
  const handleDeploy = async () => {
    if (! isAuthenticated) {
      return;
    }

    if (activeMetadata === null) {
      errorToast('Choose a metadata');
      return;
    }

    // close alert dialog and open info toast
    onClose();
    // infoToast("Veryfing parameters", "Checking all parameters are correct");

    // const name = collectionName.trim();
    // if (name === "") {
    //   errorToast("Missing collection name");
    //   return;
    // }

    // const description = collectionDescription.trim();
    // if (description === "") {
    //   errorToast("Missing collection description");
    //   return;
    // }

    // if (collectionFiles.length === 0) {
    //   errorToast("Missing collection files");
    //   return;
    // }

    const tName = tokenName.trim();
    if (tName === "") {
      errorToast('Missing token name');
      return;
    }

    const creator = creatorAddress.trim();
    if (creator === "") {
      errorToast('Missing token creator');
      return;
    }

    let royalty = parseFloat(creatorFee);
    if (isNaN(royalty)) {
      errorToast('Missing creator fee');
      return;
    } else if (! creatorFeeValid) {
      errorToast('Invalid creator fee');
      return;
    }
    royalty *= 10000;

    // ERC721 specific checks
    const tSymbol = tokenSymbol.trim();
    let price = parseFloat(mintPrice);
    let mintStartTimestamp = mintStartDate; 
    mintStartTimestamp = Math.floor(Date.parse(mintStartTimestamp) / 1000);

    if (tokenType === "ERC721" || tokenType === "ERC721WL") {
      if (tSymbol === "") {
        errorToast('Missing token symbol');
        return;
      }

      if (isNaN(price)) {
        errorToast('Missing mint price');
        return;
      } else if (! mintPriceValid) {
        errorToast('Invalid mint price');
        return;
      }
      price = ethers.utils.parseEther(mintPrice.toString());

      if(mintStartTimestamp < Math.floor(Date.now() / 1000)) {
        errorToast('Invalid mint start date');
        return;
      }
    }

    // Connect wallet
    const signer = await library.getSigner()

    if (signer === null ) {
      errorToast("Unable to create signer");
      return;
    }

    // Create contract
    try {
      await signer.getAddress();
    } catch (error) {
      errorToast("Unable to connect to wallet");
      return;
    }

    // Upload files and metadata on Pinata
    // console.log(customFields)
    // const { error, success, ipfsHash } = await uploadCollectionMetadata(collectionFiles, name, description, customFields, updateInfoToast);
    // if (! success) {
    //     errorToast(error);
    //     return;
    // }

    // const baseUri = "https://blockinvest.mypinata.cloud/ipfs/" + ipfsHash + "/";
    const baseUri = process.env.REACT_APP_API_URL + "/metadata/" + activeMetadata.id + "/"
    
    // Create contract
    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    let contract;
    if (tokenType === "ERC721" || tokenType === "ERC721WL") {
        contract = new ethers.Contract(contractAddresses.factory721, factory721ABI, signer);
    } else {
        contract = new ethers.Contract(contractAddresses.factory1155, factory1155ABI, signer);
    }

    updateInfoToast("Deploying smart contract", "Creating transaction");

    try {
      let tx;

      if (tokenType === "ERC721") {
          tx = await contract.deployTokenNFT(tName, tSymbol, baseUri, creator, royalty, price, mintStartTimestamp);
      } else if (tokenType === "ERC721WL") {
          tx = await contract.deployTokenWithWL(tName, tSymbol, baseUri, creator, royalty, price, mintStartTimestamp);
      } else if (tokenType === "ERC1155") {
          tx = await contract.deployToken1155(tName, baseUri, creator, royalty);
      } else if (tokenType === "ERC1155WL") {
          tx = await contract.deployTokenWithWL(tName, baseUri, creator, royalty);
      }

      const explorer = contractAddresses.explorer + tx.hash;
      const description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )

      updateInfoToast("Deploying NFT", description)
      await tx.wait();

      // Process logs and add collection to database
      // const abi = tokenType === "ERC721" || tokenType === "ERC721WL" ? factory721ABI : factory1155ABI;
      // let iface = new ethers.utils.Interface(abi)
      // const eventIndex =  tokenType === "ERC721" || tokenType === "ERC721WL" ? 2 : 3;
      // let newTokenAddress = iface.parseLog(receipt.logs[eventIndex]).args[0]
      // let whitelistAddress = ethers.constants.AddressZero
      
      // if (tokenType.includes("WL")) {
      //   const wlIndex =  tokenType === "ERC721WL" ? 6 : 7;
      //   whitelistAddress = iface.parseLog(receipt.logs[wlIndex]).args[1]
      // }

    // await addCollectionToDatabase(newTokenAddress, chainId, maxSupply, baseUri, creator, collector, tokenType, tName, tSymbol, biFee, royalty, whitelistAddress, price.toString(), mintStartTimestamp)

      // Close all toasts
      toast.closeAll();

      successToast("NFT successfully minted", description)
    } catch (error) {
      // Close all toasts
      toast.closeAll();

      errorToast("Unable to mint your NFT")
      console.log(error.message)
    }
  }

  // const addCollectionToDatabase = async (contractAddress, chainid, size, baseUri, owner, creator, standard, tokenName, tokenSymbol, biFee, creatorFee, whitelist, mintPrice, mintDate) => {
  //   axios.post(process.env.REACT_APP_API_URL + "/collection", {
  //     contractAddress: contractAddress,
  //     chainId: chainid,
  //     size: size,
  //     baseUri: baseUri,
  //     owner: owner,
  //     creator: creator,
  //     standard: standard,
  //     tokenName: tokenName,
  //     tokenSymbol: tokenSymbol,
  //     blockInvestFee: biFee,
  //     creatorFee: creatorFee,
  //     whitelistAddress: whitelist,
  //     mintPrice: mintPrice,
  //     mintDate: mintDate
  //   }, {
  //     headers: {
  //       'Authorization': 'Bearer ' + window.localStorage.getItem("accessToken")
  //     }
  //   })
  //   .then(response => {

  //   })
  //   .catch(error => {
  //     console.log(error)
  //   })
  // }

  return (
    <>
      <Container py={{ base: '4', md: '8' }}>
        <Stack spacing="5">
          <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
            <Box>
              <Text fontSize="lg" fontWeight="medium">
                Token
              </Text>
              <Text color="muted" fontSize="sm">
                Set your token informations
              </Text>
            </Box>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            <FormControl id="standard">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Standard
                  {' '} <InfoPopover header="Token standard" body="ERC721 is the standard for NFTs while ERC1155 is the standard for semi-fungible tokens. With ERC721 all items will be unique while with ERC1155 each item can have multiple copies."/>
                </FormLabel>

                <Tabs colorScheme="blue" isFitted variant="unstyled" maxW={{ md: '3xl' }}>
                  <TabList>
                    {tokenTypes.map((item) => (
                        <Tab
                          key={item.code}
                          fontSize="md"
                          _selected={{
                            color: 'accent',
                          }}
                          onClick={() => {setTokenType(item.code)}}
                        >
                          {item.type}
                        </Tab>
                    ))}
                  </TabList>
                  <TabIndicator mt="4" height={1} borderTopRadius="md" bg="accent" />
                </Tabs>
              </Stack>
            </FormControl>

            <FormControl id="metadata">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Metadata
                  {' '} <InfoPopover header="Metadata" body="Set of fields, attributes and media files"/>
                </FormLabel>
                
                <Menu colorScheme='blue'>
                  <MenuButton as={Button} rightIcon={<BsChevronBarDown />}>
                    { 
                      activeMetadata ? activeMetadata.id + " - " + activeMetadata.contentName : "Choose a metadata to use"
                    }
                  </MenuButton>
                  <MenuList maxHeight="300px" overflowY="scroll">
                    {
                      metadatas.map(metadata => (
                        <MenuItem onClick={() => { setActiveMetadata(metadata) }} >{metadata.id} - {metadata.contentName}</MenuItem>
                      ))
                    }
                  </MenuList>
                </Menu>
              </Stack>
            </FormControl>

            <FormControl id="name">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Name
                  {' '} <InfoPopover header="Name" body="Name of the token. This will be visible on public explorers"/>
                </FormLabel>
                <Input maxW={{ md: '3xl' }} placeholder="DigitalDiamonds" value={tokenName} 
                  onChange={(e) => {setTokenName(e.target.value)}} />
              </Stack>
            </FormControl>

            {
              (tokenType === 'ERC721' || tokenType === "ERC721WL") && (
                <FormControl id="symbol">
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '1.5', md: '8' }}
                    justify="space-between"
                  >
                    <FormLabel variant="inline">
                      Symbol
                      {' '} <InfoPopover header="Symbol" body="Symbol of the token. This will be visible on public explorers"/>
                    </FormLabel>
                    <Input maxW={{ md: '3xl' }} placeholder="DD" value={tokenSymbol}
                      onChange={(e) => {setTokenSymbol(e.target.value)}} />
                  </Stack>
                </FormControl>
              )
            }

            <FormControl id="creator">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Creator address
                  {' '} <InfoPopover header="Creator adress" body="Address that will own the collection. This address will also be able to manage the collection"/>
                </FormLabel>
                <Input maxW={{ md: '3xl' }} placeholder="0xaab7a0212d08e9ef882177add6a9404b1727e993" value={creatorAddress}
                  onChange={(e) => {setCreatorAddress(e.target.value)}} />
              </Stack>
            </FormControl>

            {/* <FormControl id="collector">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Fee collector address
                  {' '} <InfoPopover header="Fee collector address" body="Address that will receive BlockInvest fees"/>
                </FormLabel>
                <Input maxW={{ md: '3xl' }} placeholder="0xaab7a0212d08e9ef882177add6a9404b1727e993" value={feeCollectorAddress}
                  onChange={(e) => {setFeeCollectorAddress(e.target.value)}}/>
              </Stack>
            </FormControl> */}

            <FormControl id="creator-fee">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Creator fee
                  {' '} <InfoPopover header="Creator fee" body="Creator royalties in percentage. This fee only apply on on-chain marketplaces that support ERC2981"/>
                </FormLabel>
                
                <InputGroup maxW={{ md: '3xl' }}>
                  <Input placeholder="7.5" value={creatorFee} isInvalid={! creatorFeeValid} onChange={(e) => {handleCreatorFeeField(e.target.value)}} />
                  <InputRightAddon children="%"/>
                </InputGroup>
              </Stack>
            </FormControl>

            <FormControl id="bi-fee">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  BlockInvest fee
                  {' '} <InfoPopover header="BlockInvest fee" body="BlockInvest royalties in percentage and cannot be less than 2.5%. This fee only apply on on-chain marketplaces that support ERC2981"/>
                </FormLabel>
                <InputGroup maxW={{ md: '3xl' }}>
                  <Input value="2.5" readOnly={true} />
                  <InputRightAddon children="%"/>
                </InputGroup>
              </Stack>
            </FormControl>

            {
              (tokenType === 'ERC721' || tokenType === "ERC721WL") && (
                <FormControl id="mint-price">
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '1.5', md: '8' }}
                    justify="space-between"
                  >
                    <FormLabel variant="inline">
                      Mint price
                      {' '} <InfoPopover header="Mint price" body="Base price in the native cryptocurrency of the selected blockchain (ETH for Ethereum, MATIC for Polygon)"/>
                    </FormLabel>
                    
                    <InputGroup maxW={{ md: '3xl' }}>
                      <Input placeholder="0.1" value={mintPrice} isInvalid={! mintPriceValid} onChange={(e) => {handleMintPriceField(e.target.value)}} />
                      <InputRightAddon children={ (chainId === 5 || chainId === 1) ? "ETH" : "MATIC"} />
                    </InputGroup>
                  </Stack>
                </FormControl>
              )
            }
            
            {
              (tokenType === 'ERC721' || tokenType === "ERC721WL") && (
                <FormControl id="mint-start">
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '1.5', md: '8' }}
                    justify="space-between"
                  >
                    <FormLabel variant="inline">
                      Mint start
                      {' '} <InfoPopover header="Mint start" body="From this date anyone will be able to mint assets from this collection"/>
                    </FormLabel>
                    
                    <Flex maxW={{ md: '3xl' }} w="100%">
                      <ReactDatePicker showPopperArrow={true} selected={mintStartDate} onChange={(date) => setMintStartDate(date)} 
                        showTimeSelect dateFormat="Pp" timeIntervals="15" ateFormat="yyyy/MM/dd" />
                    </Flex>
                  </Stack>
                </FormControl>
              )
            }
          </Stack>

          <Flex direction="row-reverse">
            <Button variant="primary" onClick={onOpen}>Deploy</Button>
          </Flex>
        </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={confirmRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Deploy your NFT
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to deploy the NFT with these parameters?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={confirmRef} onClick={onClose}>
                No
              </Button>
              <Button variant="primary" onClick={handleDeploy} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {
        ! isConnected && (
          <Box h="55vh">
            <Center h="100%">
              <Image src={Logo} maxW={isDesktop ? "500px" : "225px"} />
            </Center>
          </Box>
        )
      }
      </Container>
    </>
  )
}

export default Deployer;