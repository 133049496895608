import * as React from 'react'

import addresses from '../resources/addresses.json';
import { 
  Box, 
  Center, 
  Container, 
  Flex, 
  Spinner, 
  useToast,
  Text
} from '@chakra-ui/react';
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { MarketplaceCard } from "../components/marketplace/MarketplaceCard"
import { CollectionGrid } from '../components/collection/CollectionGrid';
import axios from 'axios';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { MarketplaceCardBuy } from '../components/marketplace/MarketplaceCardBuy';
import { useWeb3React } from '@web3-react/core'

function Marketplace() {
  const toast = useToast();
  const { library, active, account, chainId } = useWeb3React()

  // states
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [itemsToShow, setItemsToShow] = React.useState([]);
  const [firstTime, setFirstTime] = React.useState(true);
  const [collectionNames, setCollectionNames] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [collections, setCollections] = React.useState({});
  const [showCollection, setShowCollection] = React.useState(true)
  const [targetCollection, setTargetCollection] = React.useState("")
  const [sellTokens, setSellTokens] = React.useState([])
  const [connectedWallet, setConnectedWallet] = React.useState("")
  const [targetCollectionOrders, setTargetCollectionOrders] = React.useState([])

  // effetc
  React.useEffect(() => {
    if (active) {
      updateItems().then();
    }

    setFirstTime(false);
  }, [active, account, chainId])

  const updateItems = async () => {
    setIsLoading(true)
    const signer = await library.getSigner();

    if (! signer) {
      toast({
        title: "Connect your wallet",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false)
      return
    }

    const walletAddress = await signer.getAddress();
    setConnectedWallet(walletAddress)

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    setSellTokens(contractAddresses.tokens)

    const response = await axios.get(process.env.REACT_APP_API_URL + '/marketplace?chainId=' + chainId.toString())
    if (response.status !== 200) {
      setCollections({})
      setItems({})
      setItemsToShow({})
      setShowCollection(true)
      setIsLoading(false);

      toast({
        title: "Unable to get NFTs on the marketplace",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      return
    }

    setCollections(response.data.collections)
    setItemsToShow(response.data.collections)

    const individualNames = []
    response.data.names.forEach(name => {

      if (! individualNames.find(item => item.label === name)) {
        individualNames.push({
            value: name,
            label: name,
        });
      }
    })

    console.log("INDIVIDUAL", individualNames)

    setCollectionNames(individualNames) 

    setShowCollection(true)
    setIsLoading(false)
  }

  const handleShowCollection = async (address) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/marketplace/order?collection=' + address);
    if (response.status != 200) {
      toast({
        title: "Unable to get NFT orders",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return 
    }

    const orders = response.data.orders
    setTargetCollectionOrders(orders)

    setShowCollection(false);
    setTargetCollection(address)
  }

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      // the item selected
      let toShow = {};

      for (let i = 0; i < selectedItems.length; i++) {
        const collectionKeys = Object.keys(collections)
        const collectionsLength =  collectionKeys.length

        for (let j = 0; j < collectionsLength; j++) {
          const collection = collections[collectionKeys[j]];

          if (collection.tokenName.includes(selectedItems[i].label)) {
            toShow[collectionKeys[j]] = collection
          }
        }
      }

      if (selectedItems.length === 0) {
        setItemsToShow(collections);
      } else {
        setItemsToShow(toShow);
      }
      
      setSelectedItems(selectedItems)
    } else {
      setItemsToShow(collections);
    }
  }

  // functions
  return (
    <>
    <Container py={{ base: '4', md: '8' }}>
    {
      // On load
      isLoading && (
        <Box w="100%" h="55vh">
          <Center h="100%">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        </Box>
      )
    }

    {
      // wallet not connected
      ! isLoading && Object.keys(collections).length === 0 && (
        <Box w="100%" h="60vh" />
      )
    } 

    {
      ! isLoading && Object.keys(collections).length > 0 && (
        <>
        {
          showCollection && (
            <CUIAutoComplete
              label="Looking for a specific collection?"
              placeholder={"Type its name"}
              disableCreateItem={true}
              items={collectionNames}
              tagStyleProps={{
                rounded: "full",
                pt: 1,
                pb: 2,
                px: 2,
                fontSize: "1rem"
              }}
              selectedItems={selectedItems}
              onSelectedItemsChange={(changes) =>
                handleSelectedItemsChange(changes.selectedItems)
              }
            />
          )
        }
    
        {
          ! showCollection && (
            <Flex w="full" pb="6" alignItems='center' onClick={() => {setShowCollection(true)}}  style={{ cursor: 'pointer' }}>
              <ArrowBackIcon color='blue.500' />
              <Text color='blue.500' fontWeight='semibold'> Back to collections</Text>
            </Flex>
          )
        }

        {
          showCollection && (
            <>
            <CollectionGrid>
              {
                Object.keys(itemsToShow).map((address) => (
                  <MarketplaceCard key={address} name={collections[address].tokenName} image={collections[address].image} address={address} showCollection={handleShowCollection} />
                ))
              }
            </CollectionGrid>
            </>
          )
        }

        {
          ! showCollection && (
            <>
            <CollectionGrid>
              {
                targetCollectionOrders.map((item) => (
                  <MarketplaceCardBuy key={item.collection + item.tokenId} is721={collections[targetCollection].standard.includes("ERC721") ? true : false} item={item} collection={targetCollection} sellTokens={sellTokens} 
                  connectedWallet={connectedWallet} onUpdate={updateItems}/>
                ))
              }
            </CollectionGrid>
            </>
          )
        }
        

        </>
      )
    }

    </Container>
    </>
  )
}

export default Marketplace;