import { Button, FormControl, FormLabel, Input, Stack, useColorModeValue, useToast } from '@chakra-ui/react'
import { BigNumber, ethers } from 'ethers'
import * as React from 'react'
import { getConnectedWallet } from '../../utils/wallet'

import tokenERC20ABI from '../../resources/tokenERC20.json'
import addresses from '../../resources/addresses.json';
import { openInNewTab } from '../../utils/externalLink';
import { useWeb3React } from '@web3-react/core'

import marketplaceABI from "../../resources/marketplaceABI.json";

export const MarketplaceBuyForm = (props) => {
  const { item, buyToken, onUpdate } = props
  const { amount, is721, contract, tokenId, price, paymentToken, orderId } = item

  const { library, chainId, account } = useWeb3React()
  const toast = useToast();
  const toastIdRef = React.useRef()

  const focusBorderColor = useColorModeValue('blue.500', 'blue.200')

  const [buyAmount, setBuyAmount] = React.useState(1);
  const [amountValid, setAmountValid] = React.useState(true);
  
  let buyButtonClickable = true

  const handleBuy = async () => {
    if (buyAmount <= 0 || ! amountValid) {
      return;
    }

    if (! buyButtonClickable) {
      return 
    }

    buyButtonClickable = false
    const signer = await library.getSigner()

    toastIdRef.current = toast({
      title: "Approving payment",
      description: "Creating transaction",
      status: 'info',
      duration: null,
      isClosable: false,
    })

    const totalPrice = BigNumber.from(price).mul(buyAmount)
    const coinAmount = paymentToken === ethers.constants.AddressZero ? totalPrice : "0"

    // let chainId = await window.ethereum.request({ method: 'eth_chainId'});
    // chainId = parseInt(chainId, 16)
    const contractAddresses = addresses[chainId];

    // Approve
    if (paymentToken !== ethers.constants.AddressZero) {
      const token = new ethers.Contract(paymentToken, tokenERC20ABI, signer);
      const allowance = await token.allowance(account, contractAddresses.marketplace);

      if (allowance < totalPrice) {
        try {
          let tx = await token.approve(contractAddresses.marketplace, totalPrice);
    
          // update for the transaction to be completed
          const explorer = contractAddresses.explorer + tx.hash;
          let description = (
            <span>
              <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
            </span>
          )
    
          toast.update(toastIdRef.current, { title: "Approving payment", description: description })
    
          await tx.wait();
    
          // update for the transaction to be completed
          toast.update(toastIdRef.current, { title: "Payment approved", description: description, status: 'success', duration: 9000});
        } catch (error) {
          toast.closeAll();
          toast({
            title: "Unable to approve the payment",
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
    
          console.log(error.message);
          buyButtonClickable = true
          return;
        }
      }
      
    }

    // Buy 
    toast.update(toastIdRef.current, { title: "Buying NFT" })

    try {
      const marketplace = new ethers.Contract(contractAddresses.marketplace, marketplaceABI, signer);
      
      const orderStruct = [item.orderId, item.paymentToken, item.price, item.amount, item.tokenId, item.owner, item.collectionAddress, item.hasRoyalties];

      let tx = await marketplace.buy1155(orderStruct, item.signature, buyAmount, {value: coinAmount});

      // update for the transaction to be completed
      const explorer = contractAddresses.explorer + tx.hash;
      let description = (
        <span>
          <a onClick={() => { openInNewTab(explorer); }} href="#/">Click here to view on explorer</a>
        </span>
      )

      toast.update(toastIdRef.current, { title: "Buying NFT", description: description })

      await tx.wait();

      // update for the transaction to be completed
      toast.update(toastIdRef.current, { title: "NFT bought", description: description, status: 'success', duration: 9000});
      onUpdate().then()
    } catch (error) {
      toast.closeAll();
      toast({
        title: "Unable to buy the NFT",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      console.log(error.message);
      buyButtonClickable = true
      return;
    }

    buyButtonClickable = true
  }

  const handleBuyAmount = (newAmount) => {
    setBuyAmount(newAmount)

    if (isNaN(parseInt(newAmount) || newAmount > item.availableAmount)) {
      setAmountValid(false);
    } else {
      setAmountValid(true)
    }
  }

  return (
    <Stack
      as="form"
      spacing="3"
      onSubmit={(e) => {
        e.preventDefault() // manage form submission
      }}
    >
      {
        ! is721 && (
          <FormControl id="address">
            <FormLabel>How many NFTs do you want to buy?</FormLabel>
            <Input
              type="text"
              placeholder="2"
              size="lg"
              fontSize="md"
              focusBorderColor={focusBorderColor}
              isInvalid={! amountValid}
              onChange={(e) => { handleBuyAmount(e.target.value) }}
              value={buyAmount}
            />
          </FormControl>
        )
      }

      <Button
        type="submit"
        marginTop="5"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="md"
        colorScheme="blue"
        size="lg"
        onClick={handleBuy}
      >
        Buy
      </Button>
    </Stack>
  )
}   