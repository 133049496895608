import {
  AspectRatio,
  Badge,
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import { AttributesModal } from './AttributesModal'
import { isVideo } from '../../utils/file'

export const MediaCard = (props) => {

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { url, attributes, metadataId, mediaId, onUpdate, rootProps} = props

  React.useEffect(() => {
    
  }, [attributes])

  return (
    <>
      <Stack
        spacing={useBreakpointValue({
          base: '4',
          md: '5',
        })}

        {...rootProps}
      >
        <Box position="relative" maxW={"xs"}>
          <AspectRatio ratio="1">
            {
              isVideo(url) ? (
                <Box 
                  as='video'
                  controls
                  src={url}
                  alt={mediaId}
                  objectFit='contain'
                  sx={{
                    aspectRatio: '1'
                  }}
                />
              ) : (
                <Image
                  src={url}
                  alt={"media-" + mediaId}
                  draggable="false"
                  fallback={<Skeleton />}
                  height="100%"
                  borderRadius={["xl", "md"]}
                />
              )
            }
          </AspectRatio>
        </Box>
        <HStack maxW={"xs"} justify="center">
          <Button colorScheme="blue" onClick={onOpen}>
            Set attributes
          </Button>
        </HStack>
      </Stack>

      <AttributesModal isOpen={isOpen} onClose={onClose} mediaId={mediaId} metadataId={metadataId} initialAttributes={attributes} onUpdate={onUpdate}/>
    </>
  )
}