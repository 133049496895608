// true is collection is a NON fungible token (collection is an object
// taken directly from the /collection endpoint)
export const isNonFungible = (collection) => {
  return (collection.standard === "ERC721" || collection.standard === "ERC721WL")
}

export const getTomorrowDate = () => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}