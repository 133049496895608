import { Box, ButtonGroup, Container, IconButton, Image, Link, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { FaLinkedin } from 'react-icons/fa'
import Logo from '../resources/logo.png';

export const Footer = () => (
  <Container
    as="footer"
    role="contentinfo"
    py={{
      base: '12',
      md: '16',
    }}
  >
    <Stack
      spacing={{
        base: '4',
        md: '5',
      }}
    >
      <Stack justify="space-between" direction="row" align="center">
        <Box>
          <Image src={Logo} alt="Logo" maxW="100px"/>
        </Box>
        {/* <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/realhouse/"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
        </ButtonGroup> */}
      </Stack>
      <Text fontSize="sm" color="subtle">
        &copy; {new Date().getFullYear()} Powered by RealHouse Srl - Via Borromei n. 2, 20123 Milano - Partita IVA 10829240968 - Cap. sociale  €84.211,00 I.V.
      </Text>
    </Stack>
  </Container>
)

export default Footer