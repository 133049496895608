import { Button, Divider, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, Text, useToast } from "@chakra-ui/react"
import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import { ethers } from "ethers"
import { arrayify } from "ethers/lib/utils"
import { useEffect } from "react"
import { useState } from "react"

export const AttributesModal = (props) => {

  const toast = useToast()
  const { library, account } = useWeb3React()
  const { isOpen, onClose, initialAttributes, metadataId, mediaId, onUpdate } = props

  useEffect(() => {
    setAttributes(initialAttributes)
  }, [initialAttributes])

  const [attributes, setAttributes] = useState(initialAttributes)

  const updateAttribute = (index, value) => {
    const currentAttributes = [...attributes]

    currentAttributes[index].value = value

    setAttributes(currentAttributes)
  }

  const handleUpdateAttributes = async () => {
    let message = JSON.stringify(attributes)
    message = arrayify(ethers.utils.id(message))

    const signer = await library.getSigner()
    const signature = await signer.signMessage(message)

    axios.post(process.env.REACT_APP_API_URL + '/metadata/' + metadataId + '/attributes/' + mediaId, {
      attributes: attributes,
      signature: signature,
      owner: account
    })
    .then((response) => {
      toast({
        title: "Attributes updated",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      onClose()
      onUpdate()
    })
    .catch (error => {
      console.log("Unable to update attributes: ", error.message)
      toast({
        title: "Unable to update attributes",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    })
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={ onClose }
      size="xl"
      blockScrollOnMount={true}
      trapFocus={true}
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" mx="4">
        <ModalCloseButton size="lg" backgroundColor="gray.50" onClick={handleClose}/>
        <ModalBody>
          <Stack
            maxW="lg"
            mx="auto"
            py={{
              base: '4',
              md: '4',
            }}
          >
            <Stack spacing="1" textAlign="center">
              <Text fontSize="lg">Attributes for NFT ID {mediaId}</Text>
            </Stack>
            
            {
              attributes.map((attribute, index) => (
                <>
                <FormControl w="100%">
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '3', md: '1' }}
                    py="3"
                    justify="space-between"
                  >
                    <FormLabel variant="inline">{attribute.trait_type}</FormLabel>
                    <Input maxW={{ md: '3xl' }} placeholder={attribute.trait_type} value={attribute.value} onChange={(e) => { updateAttribute(index, e.target.value) }} />
                  </Stack>
                </FormControl>
                <Divider />
                </>
              ))
            }

          <Flex w="100%" direction="row-reverse" py="2">
            <Button variant="primary" onClick={handleUpdateAttributes}>
              Save
            </Button>
          </Flex>
          </Stack>
        </ModalBody>
      
      </ModalContent>
    </Modal>
  )
  
}